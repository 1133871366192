const templates = [
  {
    key: 'light' as const,
    name: 'Light',
    template: {
      layout: 's',
      charts: [
        {
          theme: 'Light',
          panes: [
            {
              sources: [
                {
                  type: 'MainSeries',
                  id: '_seriesId',
                  state: {
                    style: 1,
                    esdShowDividends: true,
                    esdShowSplits: true,
                    esdShowEarnings: true,
                    esdShowBreaks: false,
                    esdBreaksStyle: {
                      color: 'rgba( 235, 77, 92, 1)',
                      style: 2,
                      width: 1,
                    },
                    esdFlagSize: 2,
                    showCountdown: false,
                    showInDataWindow: true,
                    visible: true,
                    showPriceLine: true,
                    priceLineWidth: 1,
                    priceLineColor: '',
                    baseLineColor: '#B2B5BE',
                    showPrevClosePriceLine: false,
                    prevClosePriceLineWidth: 1,
                    prevClosePriceLineColor: 'rgba( 85, 85, 85, 1)',
                    minTick: 'default',
                    extendedHours: false,
                    sessVis: false,
                    statusViewStyle: {
                      fontSize: 17,
                      showExchange: true,
                      showInterval: true,
                      showSymbolAsDescription: false,
                    },
                    candleStyle: {
                      upColor: '#009900',
                      downColor: '#B80E0C',
                      drawWick: true,
                      drawBorder: true,
                      borderColor: '#000000',
                      borderUpColor: '#000000',
                      borderDownColor: '#000000',
                      wickColor: '#737375',
                      wickUpColor: '#000000',
                      wickDownColor: '#000000',
                      barColorsOnPrevClose: false,
                    },
                    hollowCandleStyle: {
                      upColor: '#53b987',
                      downColor: '#eb4d5c',
                      drawWick: true,
                      drawBorder: true,
                      borderColor: '#378658',
                      borderUpColor: '#53b987',
                      borderDownColor: '#eb4d5c',
                      wickColor: '#737375',
                      wickUpColor: '#a9dcc3',
                      wickDownColor: '#f5a6ae',
                    },
                    haStyle: {
                      upColor: '#53b987',
                      downColor: '#eb4d5c',
                      drawWick: true,
                      drawBorder: true,
                      borderColor: '#378658',
                      borderUpColor: '#53b987',
                      borderDownColor: '#eb4d5c',
                      wickColor: '#737375',
                      wickUpColor: '#53b987',
                      wickDownColor: '#eb4d5c',
                      showRealLastPrice: false,
                      barColorsOnPrevClose: false,
                      inputs: {},
                      inputInfo: {},
                    },
                    barStyle: {
                      upColor: '#53b987',
                      downColor: '#eb4d5c',
                      barColorsOnPrevClose: false,
                      dontDrawOpen: false,
                    },
                    lineStyle: {
                      color: '#0303F7',
                      linestyle: 0,
                      linewidth: 1,
                      priceSource: 'close',
                      styleType: 2,
                    },
                    areaStyle: {
                      color1: 'rgba(96, 96, 144, 0.5)',
                      color2: 'rgba(1, 246, 245, 0.5)',
                      linecolor: '#0094FF',
                      linestyle: 0,
                      linewidth: 1,
                      priceSource: 'close',
                      transparency: 50,
                    },
                    renkoStyle: {
                      upColor: '#53b987',
                      downColor: '#eb4d5c',
                      borderUpColor: '#53b987',
                      borderDownColor: '#eb4d5c',
                      upColorProjection: '#a9dcc3',
                      downColorProjection: '#f5a6ae',
                      borderUpColorProjection: '#a9dcc3',
                      borderDownColorProjection: '#f5a6ae',
                      wickUpColor: '#a9dcc3',
                      wickDownColor: '#f5a6ae',
                      inputs: {
                        source: 'close',
                        boxSize: 3,
                        style: 'ATR',
                        atrLength: 14,
                        wicks: true,
                      },
                      inputInfo: {
                        source: { name: 'Source' },
                        boxSize: { name: 'Box size' },
                        style: { name: 'Style' },
                        atrLength: { name: 'ATR Length' },
                        wicks: { name: 'Wicks' },
                      },
                    },
                    pbStyle: {
                      upColor: '#53b987',
                      downColor: '#eb4d5c',
                      borderUpColor: '#53b987',
                      borderDownColor: '#eb4d5c',
                      upColorProjection: '#a9dcc3',
                      downColorProjection: '#f5a6ae',
                      borderUpColorProjection: '#a9dcc3',
                      borderDownColorProjection: '#f5a6ae',
                      inputs: { source: 'close', lb: 3 },
                      inputInfo: {
                        source: { name: 'Source' },
                        lb: { name: 'Number of line' },
                      },
                    },
                    kagiStyle: {
                      upColor: '#53b987',
                      downColor: '#eb4d5c',
                      upColorProjection: '#a9dcc3',
                      downColorProjection: '#f5a6ae',
                      inputs: {
                        source: 'close',
                        style: 'ATR',
                        atrLength: 14,
                        reversalAmount: 1,
                      },
                      inputInfo: {
                        source: { name: 'Source' },
                        style: { name: 'Style' },
                        atrLength: { name: 'ATR Length' },
                        reversalAmount: { name: 'Reversal amount' },
                      },
                    },
                    pnfStyle: {
                      upColor: '#53b987',
                      downColor: '#eb4d5c',
                      upColorProjection: '#a9dcc3',
                      downColorProjection: '#f5a6ae',
                      inputs: {
                        sources: 'Close',
                        reversalAmount: 3,
                        boxSize: 1,
                        style: 'ATR',
                        atrLength: 14,
                      },
                      inputInfo: {
                        sources: { name: 'Source' },
                        boxSize: { name: 'Box size' },
                        reversalAmount: { name: 'Reversal amount' },
                        style: { name: 'Style' },
                        atrLength: { name: 'ATR Length' },
                      },
                    },
                    baselineStyle: {
                      baselineColor: 'rgba( 117, 134, 150, 1)',
                      topFillColor1: 'rgba( 83, 185, 135, 0.1)',
                      topFillColor2: 'rgba( 83, 185, 135, 0.1)',
                      bottomFillColor1: 'rgba( 235, 77, 92, 0.1)',
                      bottomFillColor2: 'rgba( 235, 77, 92, 0.1)',
                      topLineColor: 'rgba( 83, 185, 135, 1)',
                      bottomLineColor: 'rgba( 235, 77, 92, 1)',
                      topLineWidth: 1,
                      bottomLineWidth: 1,
                      priceSource: 'close',
                      transparency: 50,
                      baseLevelPercentage: 50,
                    },
                    rangeStyle: {
                      upColor: '#53b987',
                      downColor: '#eb4d5c',
                      upColorProjection: '#a9dcc3',
                      downColorProjection: '#f5a6ae',
                      inputs: { range: 10, phantomBars: false },
                      inputInfo: {
                        range: { name: 'Range' },
                        phantomBars: { name: 'Phantom Bars' },
                      },
                    },
                    symbol: 'IHSG',
                    shortName: 'IHSG',
                    timeframe: '',
                    onWidget: false,
                    interval: 'D',
                    priceAxisProperties: {
                      autoScale: true,
                      autoScaleDisabled: false,
                      lockScale: false,
                      percentage: false,
                      percentageDisabled: false,
                      log: false,
                      logDisabled: false,
                      alignLabels: false,
                      indexedTo100: false,
                    },
                    showSessions: false,
                  },
                  zorder: -1,
                  haStyle: { studyId: 'BarSetHeikenAshi@tv-basicstudies-60' },
                  renkoStyle: { studyId: 'BarSetRenko@tv-prostudies-15' },
                  pbStyle: { studyId: 'BarSetPriceBreak@tv-prostudies-15' },
                  kagiStyle: { studyId: 'BarSetKagi@tv-prostudies-15' },
                  pnfStyle: { studyId: 'BarSetPnF@tv-prostudies-15' },
                  rangeStyle: { studyId: 'BarSetRange@tv-basicstudies-72' },
                },
                {
                  type: 'study_Sessions',
                  id: 'VP10po',
                  state: {
                    graphics: {
                      vertlines: {
                        sessBreaks: {
                          color: '#4985e7',
                          style: 2,
                          visible: false,
                          width: 1,
                          name: 'Session Break',
                        },
                      },
                    },
                    linkedToSeries: true,
                    precision: 'default',
                    inputs: {},
                    styles: {},
                    palettes: {},
                    bands: {},
                    area: {},
                    showInDataWindow: true,
                    visible: true,
                    showStudyArguments: true,
                    plots: {},
                    _metainfoVersion: 44,
                    description: 'Sessions',
                    id: 'Sessions@tv-basicstudies',
                    is_hidden_study: true,
                    is_price_study: true,
                    name: 'Sessions@tv-basicstudies',
                    shortDescription: 'Sessions',
                    description_localized: 'Sessions',
                    shortId: 'Sessions',
                    packageId: 'tv-basicstudies',
                    version: '1',
                    fullId: 'Sessions@tv-basicstudies-1',
                    productId: 'tv-basicstudies',
                  },
                  zorder: -2,
                  metaInfo: {
                    palettes: {},
                    inputs: [],
                    plots: [],
                    graphics: {
                      vertlines: { sessBreaks: { name: 'Session Break' } },
                    },
                    defaults: {
                      graphics: {
                        vertlines: {
                          sessBreaks: {
                            color: '#4985e7',
                            style: 2,
                            visible: false,
                            width: 1,
                          },
                        },
                      },
                      linkedToSeries: true,
                      precision: 4,
                    },
                    _metainfoVersion: 44,
                    description: 'Sessions',
                    id: 'Sessions@tv-basicstudies-1',
                    is_hidden_study: true,
                    is_price_study: true,
                    name: 'Sessions@tv-basicstudies',
                    shortDescription: 'Sessions',
                    description_localized: 'Sessions',
                    shortId: 'Sessions',
                    packageId: 'tv-basicstudies',
                    version: '1',
                    fullId: 'Sessions@tv-basicstudies-1',
                    productId: 'tv-basicstudies',
                  },
                  ownerSource: '_seriesId',
                },
              ],
              leftAxisState: {
                m_priceRange: { m_maxValue: 0.5, m_minValue: -0.5 },
                m_isAutoScale: true,
                m_isPercentage: false,
                m_isIndexedTo100: false,
                m_isLog: false,
                m_isLockScale: false,
                m_height: 949,
                m_topMargin: 0.17,
                m_bottomMargin: 0.15,
              },
              leftAxisSources: [],
              rightAxisState: {
                m_priceRange: { m_maxValue: 6636.33, m_minValue: 5623.84 },
                m_isAutoScale: true,
                m_isPercentage: false,
                m_isIndexedTo100: false,
                m_isLog: false,
                m_isLockScale: false,
                m_height: 949,
                m_topMargin: 0.17,
                m_bottomMargin: 0.15,
              },
              rightAxisSources: ['_seriesId', 'VP10po'],
              overlayPriceScales: {},
              stretchFactor: 4484.635413319781,
              mainSourceId: '_seriesId',
            },
          ],
          timeScale: { m_barSpacing: 6, m_rightOffset: 5 },
          chartProperties: {
            paneProperties: {
              background: '#ffffff',
              gridProperties: { color: '#e1ecf2', style: 0 },
              vertGridProperties: {
                color: 'rgba(225, 236, 242, 0.27)',
                style: 0,
              },
              horzGridProperties: {
                color: 'rgba(225, 236, 242, 0.27)',
                style: 0,
              },
              crossHairProperties: {
                color: 'rgba(117, 134, 150, 1)',
                style: 2,
                transparency: 0,
                width: 1,
              },
              topMargin: 17,
              bottomMargin: 15,
              leftAxisProperties: {
                autoScale: true,
                autoScaleDisabled: false,
                lockScale: false,
                percentage: false,
                percentageDisabled: false,
                indexedTo100: false,
                log: false,
                logDisabled: false,
                alignLabels: true,
              },
              rightAxisProperties: {
                autoScale: true,
                autoScaleDisabled: false,
                lockScale: false,
                percentage: false,
                percentageDisabled: false,
                log: false,
                logDisabled: false,
                alignLabels: true,
              },
              legendProperties: {
                showStudyArguments: true,
                showStudyTitles: true,
                showStudyValues: false,
                showSeriesTitle: true,
                showSeriesOHLC: true,
                showLegend: false,
                showBarChange: true,
                showOnlyPriceSource: true,
              },
            },
            scalesProperties: {
              showLeftScale: false,
              showRightScale: true,
              backgroundColor: '#ffffff',
              lineColor: '#555',
              textColor: '#555',
              fontSize: 11,
              scaleSeriesOnly: false,
              showSeriesLastValue: true,
              showSeriesPrevCloseValue: false,
              showStudyLastValue: true,
              showSymbolLabels: false,
              showStudyPlotLabels: false,
              barSpacing: 6,
            },
            chartEventsSourceProperties: {
              visible: true,
              futureOnly: true,
              breaks: {
                color: 'rgba(85, 85, 85, 1)',
                visible: false,
                style: 2,
                width: 1,
              },
            },
          },
          version: 2,
          timezone: 'Etc/UTC',
        },
      ],
    },
  },
  {
    key: 'dark' as const,
    name: 'Dark',
    template: {
      layout: 's',
      charts: [
        {
          theme: 'Dark',
          panes: [
            {
              sources: [
                {
                  type: 'MainSeries',
                  id: '_seriesId',
                  state: {
                    style: 1,
                    esdShowDividends: true,
                    esdShowSplits: true,
                    esdShowEarnings: true,
                    esdShowBreaks: false,
                    esdBreaksStyle: {
                      color: 'rgba( 235, 77, 92, 1)',
                      style: 2,
                      width: 1,
                    },
                    esdFlagSize: 2,
                    showCountdown: false,
                    showInDataWindow: true,
                    visible: true,
                    showPriceLine: true,
                    priceLineWidth: 1,
                    priceLineColor: '',
                    baseLineColor: '#5d606b',
                    showPrevClosePriceLine: false,
                    prevClosePriceLineWidth: 1,
                    prevClosePriceLineColor: 'rgba( 85, 85, 85, 1)',
                    minTick: 'default',
                    extendedHours: false,
                    sessVis: false,
                    statusViewStyle: {
                      fontSize: 17,
                      showExchange: true,
                      showInterval: true,
                      showSymbolAsDescription: false,
                    },
                    candleStyle: {
                      upColor: '#53b987',
                      downColor: '#eb4d5c',
                      drawWick: true,
                      drawBorder: true,
                      borderColor: '#378658',
                      borderUpColor: '#53b987',
                      borderDownColor: '#eb4d5c',
                      wickColor: '#B5B5B8',
                      wickUpColor: '#336854',
                      wickDownColor: '#7f323f',
                      barColorsOnPrevClose: false,
                    },
                    hollowCandleStyle: {
                      upColor: '#53b987',
                      downColor: '#eb4d5c',
                      drawWick: true,
                      drawBorder: true,
                      borderColor: '#378658',
                      borderUpColor: '#53b987',
                      borderDownColor: '#eb4d5c',
                      wickColor: '#B5B5B8',
                      wickUpColor: '#336854',
                      wickDownColor: '#7f323f',
                    },
                    haStyle: {
                      upColor: '#53b987',
                      downColor: '#eb4d5c',
                      drawWick: true,
                      drawBorder: true,
                      borderColor: '#378658',
                      borderUpColor: '#53b987',
                      borderDownColor: '#eb4d5c',
                      wickColor: '#B5B5B8',
                      wickUpColor: '#53b987',
                      wickDownColor: '#eb4d5c',
                      showRealLastPrice: false,
                      barColorsOnPrevClose: false,
                      inputs: {},
                      inputInfo: {},
                    },
                    barStyle: {
                      upColor: '#53b987',
                      downColor: '#eb4d5c',
                      barColorsOnPrevClose: false,
                      dontDrawOpen: false,
                    },
                    lineStyle: {
                      color: '#6FB8F7',
                      linestyle: 0,
                      linewidth: 1,
                      priceSource: 'close',
                      styleType: 2,
                    },
                    areaStyle: {
                      color1: '#606090',
                      color2: '#01F6F5',
                      linecolor: '#0094FF',
                      linestyle: 0,
                      linewidth: 1,
                      priceSource: 'close',
                      transparency: 50,
                    },
                    renkoStyle: {
                      upColor: '#53b987',
                      downColor: '#eb4d5c',
                      borderUpColor: '#53b987',
                      borderDownColor: '#eb4d5c',
                      upColorProjection: '#336854',
                      downColorProjection: '#7f323f',
                      borderUpColorProjection: '#336854',
                      borderDownColorProjection: '#7f323f',
                      wickUpColor: '#336854',
                      wickDownColor: '#7f323f',
                      inputs: {
                        source: 'close',
                        boxSize: 3,
                        style: 'ATR',
                        atrLength: 14,
                        wicks: true,
                      },
                      inputInfo: {
                        source: {
                          name: 'Source',
                        },
                        boxSize: {
                          name: 'Box size',
                        },
                        style: {
                          name: 'Style',
                        },
                        atrLength: {
                          name: 'ATR Length',
                        },
                        wicks: {
                          name: 'Wicks',
                        },
                      },
                    },
                    pbStyle: {
                      upColor: '#53b987',
                      downColor: '#eb4d5c',
                      borderUpColor: '#53b987',
                      borderDownColor: '#eb4d5c',
                      upColorProjection: '#336854',
                      downColorProjection: '#7f323f',
                      borderUpColorProjection: '#336854',
                      borderDownColorProjection: '#7f323f',
                      inputs: {
                        source: 'close',
                        lb: 3,
                      },
                      inputInfo: {
                        source: {
                          name: 'Source',
                        },
                        lb: {
                          name: 'Number of line',
                        },
                      },
                    },
                    kagiStyle: {
                      upColor: '#53b987',
                      downColor: '#eb4d5c',
                      upColorProjection: '#336854',
                      downColorProjection: '#7f323f',
                      inputs: {
                        source: 'close',
                        style: 'ATR',
                        atrLength: 14,
                        reversalAmount: 1,
                      },
                      inputInfo: {
                        source: {
                          name: 'Source',
                        },
                        style: {
                          name: 'Style',
                        },
                        atrLength: {
                          name: 'ATR Length',
                        },
                        reversalAmount: {
                          name: 'Reversal amount',
                        },
                      },
                    },
                    pnfStyle: {
                      upColor: '#53b987',
                      downColor: '#eb4d5c',
                      upColorProjection: '#336854',
                      downColorProjection: '#7f323f',
                      inputs: {
                        sources: 'Close',
                        reversalAmount: 3,
                        boxSize: 1,
                        style: 'ATR',
                        atrLength: 14,
                      },
                      inputInfo: {
                        sources: {
                          name: 'Source',
                        },
                        boxSize: {
                          name: 'Box size',
                        },
                        reversalAmount: {
                          name: 'Reversal amount',
                        },
                        style: {
                          name: 'Style',
                        },
                        atrLength: {
                          name: 'ATR Length',
                        },
                      },
                    },
                    baselineStyle: {
                      baselineColor: 'rgba( 117, 134, 150, 1)',
                      topFillColor1: 'rgba( 83, 185, 135, 0.1)',
                      topFillColor2: 'rgba( 83, 185, 135, 0.1)',
                      bottomFillColor1: 'rgba( 235, 77, 92, 0.1)',
                      bottomFillColor2: 'rgba( 235, 77, 92, 0.1)',
                      topLineColor: 'rgba( 83, 185, 135, 1)',
                      bottomLineColor: 'rgba( 235, 77, 92, 1)',
                      topLineWidth: 1,
                      bottomLineWidth: 1,
                      priceSource: 'close',
                      transparency: 50,
                      baseLevelPercentage: 50,
                    },
                    rangeStyle: {
                      upColor: '#53b987',
                      downColor: '#eb4d5c',
                      upColorProjection: '#336854',
                      downColorProjection: '#7f323f',
                      inputs: {
                        range: 10,
                        phantomBars: false,
                      },
                      inputInfo: {
                        range: {
                          name: 'Range',
                        },
                        phantomBars: {
                          name: 'Phantom Bars',
                        },
                      },
                    },
                    symbol: 'BBCA',
                    shortName: 'BBCA',
                    timeframe: '',
                    onWidget: false,
                    interval: 'D',
                    priceAxisProperties: {
                      autoScale: true,
                      autoScaleDisabled: false,
                      lockScale: false,
                      percentage: false,
                      percentageDisabled: false,
                      log: false,
                      logDisabled: false,
                      alignLabels: false,
                      indexedTo100: false,
                    },
                    showSessions: false,
                  },
                  zorder: -1,
                  haStyle: {
                    studyId: 'BarSetHeikenAshi@tv-basicstudies-60',
                  },
                  renkoStyle: {
                    studyId: 'BarSetRenko@tv-prostudies-15',
                  },
                  pbStyle: {
                    studyId: 'BarSetPriceBreak@tv-prostudies-15',
                  },
                  kagiStyle: {
                    studyId: 'BarSetKagi@tv-prostudies-15',
                  },
                  pnfStyle: {
                    studyId: 'BarSetPnF@tv-prostudies-15',
                  },
                  rangeStyle: {
                    studyId: 'BarSetRange@tv-basicstudies-72',
                  },
                },
                {
                  type: 'study_Sessions',
                  id: 'VP10po',
                  state: {
                    graphics: {
                      vertlines: {
                        sessBreaks: {
                          color: '#4985e7',
                          style: 2,
                          visible: false,
                          width: 1,
                          name: 'Session Break',
                        },
                      },
                    },
                    linkedToSeries: true,
                    precision: 'default',
                    inputs: {},
                    styles: {},
                    palettes: {},
                    bands: {},
                    area: {},
                    showInDataWindow: true,
                    visible: true,
                    showStudyArguments: true,
                    plots: {},
                    _metainfoVersion: 44,
                    description: 'Sessions',
                    id: 'Sessions@tv-basicstudies',
                    is_hidden_study: true,
                    is_price_study: true,
                    name: 'Sessions@tv-basicstudies',
                    shortDescription: 'Sessions',
                    description_localized: 'Sessions',
                    shortId: 'Sessions',
                    packageId: 'tv-basicstudies',
                    version: '1',
                    fullId: 'Sessions@tv-basicstudies-1',
                    productId: 'tv-basicstudies',
                  },
                  zorder: -2,
                  metaInfo: {
                    palettes: {},
                    inputs: [],
                    plots: [],
                    graphics: {
                      vertlines: {
                        sessBreaks: {
                          name: 'Session Break',
                        },
                      },
                    },
                    defaults: {
                      graphics: {
                        vertlines: {
                          sessBreaks: {
                            color: '#4985e7',
                            style: 2,
                            visible: false,
                            width: 1,
                          },
                        },
                      },
                      linkedToSeries: true,
                      precision: 4,
                    },
                    _metainfoVersion: 44,
                    description: 'Sessions',
                    id: 'Sessions@tv-basicstudies-1',
                    is_hidden_study: true,
                    is_price_study: true,
                    name: 'Sessions@tv-basicstudies',
                    shortDescription: 'Sessions',
                    description_localized: 'Sessions',
                    shortId: 'Sessions',
                    packageId: 'tv-basicstudies',
                    version: '1',
                    fullId: 'Sessions@tv-basicstudies-1',
                    productId: 'tv-basicstudies',
                  },
                  ownerSource: '_seriesId',
                },
                {
                  type: 'LineToolABCD',
                  id: '44EV8q',
                  state: {
                    intervalsVisibilities: {
                      seconds: true,
                      secondsFrom: 1,
                      secondsTo: 59,
                      minutes: true,
                      minutesFrom: 1,
                      minutesTo: 59,
                      hours: true,
                      hoursFrom: 1,
                      hoursTo: 24,
                      days: true,
                      daysFrom: 1,
                      daysTo: 366,
                      weeks: true,
                      months: true,
                      ranges: true,
                    },
                    clonable: true,
                    color: 'rgba( 0, 155, 0, 1)',
                    textcolor: 'rgba( 255, 255, 255, 1)',
                    font: 'Verdana',
                    fontsize: 12,
                    bold: false,
                    italic: false,
                    linewidth: 2,
                    frozen: false,
                    visible: true,
                    symbol: 'BBCA',
                    interval: 'D',
                    lastUpdateTime: 0,
                    _isActualInterval: true,
                    fixedSize: true,
                  },
                  points: [
                    {
                      time_t: 1577379600,
                      offset: 0,
                      price: 33529.98191418593,
                    },
                    {
                      time_t: 1577379600,
                      offset: 0,
                      price: 33529.98191418593,
                    },
                    {
                      time_t: 1575824400,
                      offset: 0,
                      price: 34666.94557625146,
                    },
                    {
                      time_t: 1577898000,
                      offset: 0,
                      price: 34901.28159820389,
                    },
                  ],
                  zorder: 4,
                  linkKey: 'lvBmAe9gwb3J',
                  ownerSource: '_seriesId',
                },
              ],
              leftAxisState: {
                m_priceRange: {
                  m_maxValue: 0.5,
                  m_minValue: -0.5,
                },
                m_isAutoScale: true,
                m_isPercentage: false,
                m_isIndexedTo100: false,
                m_isLog: false,
                m_isLockScale: false,
                m_height: 877,
                m_topMargin: 0.17,
                m_bottomMargin: 0.15,
              },
              leftAxisSources: [],
              rightAxisState: {
                m_priceRange: {
                  m_maxValue: 35300,
                  m_minValue: 25700,
                },
                m_isAutoScale: true,
                m_isPercentage: false,
                m_isIndexedTo100: false,
                m_isLog: false,
                m_isLockScale: false,
                m_height: 877,
                m_topMargin: 0.17,
                m_bottomMargin: 0.15,
              },
              rightAxisSources: ['_seriesId', 'VP10po'],
              overlayPriceScales: {
                '44EV8q': {
                  m_priceRange: {
                    m_maxValue: 35300,
                    m_minValue: 25700,
                  },
                  m_isAutoScale: true,
                  m_isPercentage: false,
                  m_isIndexedTo100: false,
                  m_isLog: false,
                  m_isLockScale: false,
                  m_height: 877,
                  m_topMargin: 0.17,
                  m_bottomMargin: 0.15,
                },
              },
              stretchFactor: 4484.635413319781,
              mainSourceId: '_seriesId',
            },
          ],
          timeScale: {
            m_barSpacing: 6,
            m_rightOffset: 5,
          },
          chartProperties: {
            paneProperties: {
              background: '#131722',
              gridProperties: {
                color: '#363c4e',
                style: 0,
              },
              vertGridProperties: {
                color: '#363c4e',
                style: 0,
              },
              horzGridProperties: {
                color: '#363c4e',
                style: 0,
              },
              crossHairProperties: {
                color: '#758696',
                style: 2,
                transparency: 0,
                width: 1,
              },
              topMargin: 17,
              bottomMargin: 15,
              leftAxisProperties: {
                autoScale: true,
                autoScaleDisabled: false,
                lockScale: false,
                percentage: false,
                percentageDisabled: false,
                indexedTo100: false,
                log: false,
                logDisabled: false,
                alignLabels: true,
              },
              rightAxisProperties: {
                autoScale: true,
                autoScaleDisabled: false,
                lockScale: false,
                percentage: false,
                percentageDisabled: false,
                log: false,
                logDisabled: false,
                alignLabels: true,
              },
              legendProperties: {
                showStudyArguments: true,
                showStudyTitles: true,
                showStudyValues: false,
                showSeriesTitle: true,
                showSeriesOHLC: true,
                showLegend: false,
                showBarChange: true,
                showOnlyPriceSource: true,
              },
            },
            scalesProperties: {
              showLeftScale: false,
              showRightScale: true,
              backgroundColor: '#ffffff',
              lineColor: '#787878',
              textColor: '#D9D9D9',
              fontSize: 11,
              scaleSeriesOnly: false,
              showSeriesLastValue: true,
              showSeriesPrevCloseValue: false,
              showStudyLastValue: true,
              showSymbolLabels: false,
              showStudyPlotLabels: false,
              barSpacing: 6,
            },
            chartEventsSourceProperties: {
              visible: true,
              futureOnly: true,
              breaks: {
                color: 'rgba(85, 85, 85, 1)',
                visible: false,
                style: 2,
                width: 1,
              },
            },
          },
          version: 2,
          timezone: 'Etc/UTC',
        },
      ],
    },
  },
  {
    key: 'default' as const,
    name: 'Default',
    template: {
      layout: 's',
      charts: [
        {
          panes: [
            {
              sources: [
                {
                  type: 'MainSeries',
                  id: '_seriesId',
                  zorder: 0,
                  haStyle: { studyId: 'BarSetHeikenAshi@tv-basicstudies-60' },
                  renkoStyle: { studyId: 'BarSetRenko@tv-prostudies-64' },
                  pbStyle: { studyId: 'BarSetPriceBreak@tv-prostudies-34' },
                  kagiStyle: { studyId: 'BarSetKagi@tv-prostudies-34' },
                  pnfStyle: { studyId: 'BarSetPnF@tv-prostudies-34' },
                  rangeStyle: { studyId: 'BarSetRange@tv-basicstudies-72' },
                  formattingDeps: {
                    format: 'price',
                    pricescale: 100,
                    minmov: 1,
                  },
                  state: {
                    style: 1,
                    esdShowDividends: true,
                    esdShowSplits: true,
                    esdShowEarnings: true,
                    esdShowBreaks: false,
                    esdFlagSize: 2,
                    showContinuousContractSwitches: true,
                    showContinuousContractSwitchesBreaks: false,
                    showFuturesContractExpiration: true,
                    showLastNews: true,
                    showCountdown: false,
                    bidAsk: {
                      visible: false,
                      lineStyle: 1,
                      lineWidth: 1,
                      bidLineColor: '#2962FF',
                      askLineColor: '#F7525F',
                    },
                    prePostMarket: {
                      visible: true,
                      lineStyle: 1,
                      lineWidth: 1,
                      preMarketColor: '#FB8C00',
                      postMarketColor: '#2962FF',
                    },
                    highLowAvgPrice: {
                      highLowPriceLinesVisible: false,
                      highLowPriceLabelsVisible: false,
                      averageClosePriceLineVisible: false,
                      averageClosePriceLabelVisible: false,
                      highLowPriceLinesColor: '',
                      highLowPriceLinesWidth: 1,
                      averagePriceLineColor: '',
                      averagePriceLineWidth: 1,
                    },
                    visible: true,
                    showPriceLine: true,
                    priceLineWidth: 1,
                    priceLineColor: '',
                    baseLineColor: '#B2B5BE',
                    showPrevClosePriceLine: false,
                    prevClosePriceLineWidth: 1,
                    prevClosePriceLineColor: '#555555',
                    minTick: 'default',
                    dividendsAdjustment: null,
                    backAdjustment: false,
                    settlementAsClose: true,
                    sessionId: 'regular',
                    sessVis: false,
                    statusViewStyle: {
                      fontSize: 16,
                      showExchange: true,
                      showInterval: true,
                      symbolTextSource: 'description',
                      showSymbolAsDescription: false,
                    },
                    candleStyle: {
                      upColor: '#009900',
                      downColor: '#B80E0C',
                      drawWick: true,
                      drawBorder: true,
                      borderColor: '#000000',
                      borderUpColor: '#000000',
                      borderDownColor: '#000000',
                      wickColor: '#737375',
                      wickUpColor: '#000000',
                      wickDownColor: '#000000',
                      barColorsOnPrevClose: false,
                      drawBody: true,
                    },
                    hollowCandleStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      drawWick: true,
                      drawBorder: true,
                      borderColor: '#378658',
                      borderUpColor: '#089981',
                      borderDownColor: '#F23645',
                      wickColor: '#737375',
                      wickUpColor: '#089981',
                      wickDownColor: '#F23645',
                      drawBody: true,
                    },
                    haStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      drawWick: true,
                      drawBorder: true,
                      borderColor: '#378658',
                      borderUpColor: '#089981',
                      borderDownColor: '#F23645',
                      wickColor: '#737375',
                      wickUpColor: '#089981',
                      wickDownColor: '#F23645',
                      showRealLastPrice: false,
                      barColorsOnPrevClose: false,
                      inputs: {},
                      inputInfo: {},
                      drawBody: true,
                    },
                    barStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      barColorsOnPrevClose: false,
                      dontDrawOpen: false,
                      thinBars: true,
                    },
                    hiloStyle: {
                      color: '#2962FF',
                      showBorders: true,
                      borderColor: '#2962FF',
                      showLabels: true,
                      labelColor: '#2962FF',
                      drawBody: true,
                      fontSize: 7,
                    },
                    columnStyle: {
                      upColor: 'rgba(8, 153, 129, 0.5)',
                      downColor: 'rgba(242, 54, 69, 0.5)',
                      barColorsOnPrevClose: true,
                      priceSource: 'close',
                    },
                    lineStyle: {
                      color: '#2962FF',
                      linestyle: 0,
                      linewidth: 2,
                      priceSource: 'close',
                    },
                    lineWithMarkersStyle: {
                      color: '#2962FF',
                      linestyle: 0,
                      linewidth: 2,
                      priceSource: 'close',
                    },
                    steplineStyle: {
                      color: '#2962FF',
                      linestyle: 0,
                      linewidth: 2,
                      priceSource: 'close',
                    },
                    areaStyle: {
                      color1: 'rgba(41, 98, 255, 0.28)',
                      color2: '#2962FF',
                      linecolor: '#2962FF',
                      linestyle: 0,
                      linewidth: 1,
                      priceSource: 'close',
                      transparency: 100,
                    },
                    hlcAreaStyle: {
                      highLineColor: '#089981',
                      highLineStyle: 0,
                      highLineWidth: 2,
                      lowLineColor: '#F23645',
                      lowLineStyle: 0,
                      lowLineWidth: 2,
                      closeLineColor: '#868993',
                      closeLineStyle: 0,
                      closeLineWidth: 2,
                      highCloseFillColor: 'rgba(8, 153, 129, 0.2)',
                      closeLowFillColor: 'rgba(242, 54, 69, 0.2)',
                    },
                    renkoStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      borderUpColor: '#089981',
                      borderDownColor: '#F23645',
                      upColorProjection: '#a9dcc3',
                      downColorProjection: '#f5a6ae',
                      borderUpColorProjection: '#a9dcc3',
                      borderDownColorProjection: '#f5a6ae',
                      wickUpColor: '#089981',
                      wickDownColor: '#F23645',
                      inputs: {
                        source: 'close',
                        sources: 'Close',
                        boxSize: 3,
                        style: 'ATR',
                        atrLength: 14,
                        wicks: true,
                      },
                      inputInfo: {
                        source: { name: 'Source' },
                        sources: { name: 'Source' },
                        boxSize: { name: 'Box size' },
                        style: { name: 'Style' },
                        atrLength: { name: 'ATR length' },
                        wicks: { name: 'Wicks' },
                      },
                    },
                    pbStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      borderUpColor: '#089981',
                      borderDownColor: '#F23645',
                      upColorProjection: '#a9dcc3',
                      downColorProjection: '#f5a6ae',
                      borderUpColorProjection: '#a9dcc3',
                      borderDownColorProjection: '#f5a6ae',
                      inputs: { source: 'close', lb: 3 },
                      inputInfo: {
                        source: { name: 'Source' },
                        lb: { name: 'Number of line' },
                      },
                    },
                    kagiStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      upColorProjection: '#a9dcc3',
                      downColorProjection: '#f5a6ae',
                      inputs: {
                        source: 'close',
                        style: 'ATR',
                        atrLength: 14,
                        reversalAmount: 1,
                      },
                      inputInfo: {
                        source: { name: 'Source' },
                        style: { name: 'Style' },
                        atrLength: { name: 'ATR length' },
                        reversalAmount: { name: 'Reversal amount' },
                      },
                    },
                    pnfStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      upColorProjection: '#a9dcc3',
                      downColorProjection: '#f5a6ae',
                      inputs: {
                        sources: 'Close',
                        reversalAmount: 3,
                        boxSize: 1,
                        style: 'ATR',
                        atrLength: 14,
                        oneStepBackBuilding: false,
                      },
                      inputInfo: {
                        sources: { name: 'Source' },
                        boxSize: { name: 'Box size' },
                        reversalAmount: { name: 'Reversal amount' },
                        style: { name: 'Style' },
                        atrLength: { name: 'ATR length' },
                        oneStepBackBuilding: { name: 'One step back building' },
                      },
                    },
                    baselineStyle: {
                      baselineColor: '#758696',
                      topFillColor1: 'rgba(8, 153, 129, 0.28)',
                      topFillColor2: 'rgba(8, 153, 129, 0.05)',
                      bottomFillColor1: 'rgba(242, 54, 69, 0.05)',
                      bottomFillColor2: 'rgba(242, 54, 69, 0.28)',
                      topLineColor: '#089981',
                      bottomLineColor: '#F23645',
                      topLineWidth: 1,
                      bottomLineWidth: 1,
                      priceSource: 'close',
                      transparency: 50,
                      baseLevelPercentage: 50,
                    },
                    rangeStyle: {
                      barStyle: 0,
                      upColor: '#089981',
                      downColor: '#F23645',
                      upColorProjection: '#a9dcc3',
                      downColorProjection: '#f5a6ae',
                      thinBars: true,
                      candlesUpColor: '#089981',
                      candlesDownColor: '#F23645',
                      candlesBorderUpColor: '#089981',
                      candlesBorderDownColor: '#F23645',
                      candlesWickUpColor: '#089981',
                      candlesWickDownColor: '#F23645',
                      inputs: { range: 10, phantomBars: false },
                      inputInfo: {
                        range: { name: 'Range' },
                        phantomBars: { name: 'Phantom bars' },
                      },
                    },
                    symbol: 'IHSG',
                    shortName: 'IHSG',
                    timeframe: '',
                    onWidget: false,
                    interval: 'D',
                    unitId: null,
                    showInDataWindow: true,
                    showSessions: false,
                    shortname: '',
                    currencyId: null,
                    priceAxisProperties: {
                      autoScale: true,
                      autoScaleDisabled: false,
                      lockScale: false,
                      percentage: false,
                      percentageDisabled: false,
                      log: false,
                      logDisabled: false,
                      alignLabels: true,
                      isInverted: false,
                      indexedTo100: false,
                    },
                  },
                },
                {
                  type: 'Study',
                  id: 'P1a4zR',
                  state: {
                    styles: {
                      plot_0: {
                        display: 15,
                        linestyle: 0,
                        linewidth: 2,
                        plottype: 0,
                        trackPrice: false,
                        transparency: 11,
                        color: '#D04EB1',
                        title: 'plot_0',
                      },
                      smoothedMA: {
                        display: 0,
                        linestyle: 0,
                        linewidth: 1,
                        plottype: 0,
                        trackPrice: false,
                        transparency: 0,
                        color: '#0496ff',
                        title: 'smoothedMA',
                      },
                    },
                    inputs: {
                      symbol: '',
                      length: 50,
                      source: 'close',
                      offset: 0,
                      smoothingLine: 'SMA',
                      smoothingLength: 9,
                    },
                    precision: 'default',
                    bands: {},
                    graphics: {},
                    ohlcPlots: {},
                    palettes: {},
                    filledAreasStyle: {},
                    filledAreas: {},
                    visible: true,
                    showLegendValues: true,
                    showLabelsOnPriceScale: true,
                    parentSources: {},
                    intervalsVisibilities: {
                      ticks: true,
                      seconds: true,
                      secondsFrom: 1,
                      secondsTo: 59,
                      minutes: true,
                      minutesFrom: 1,
                      minutesTo: 59,
                      hours: true,
                      hoursFrom: 1,
                      hoursTo: 24,
                      days: true,
                      daysFrom: 1,
                      daysTo: 366,
                      weeks: true,
                      weeksFrom: 1,
                      weeksTo: 52,
                      months: true,
                      monthsFrom: 1,
                      monthsTo: 12,
                      ranges: true,
                    },
                  },
                  zorder: -10000,
                  ownFirstValue: null,
                  metaInfo: {
                    palettes: {},
                    inputs: [
                      {
                        id: 'symbol',
                        name: 'Other Symbol',
                        defval: '',
                        type: 'symbol',
                        optional: true,
                        isHidden: false,
                        display: 15,
                      },
                      {
                        id: 'length',
                        name: 'Length',
                        defval: 9,
                        type: 'integer',
                        min: 1,
                        max: 10000,
                        display: 15,
                      },
                      {
                        id: 'source',
                        name: 'Source',
                        defval: 'close',
                        type: 'source',
                        options: [
                          'open',
                          'high',
                          'low',
                          'close',
                          'hl2',
                          'hlc3',
                          'ohlc4',
                        ],
                        display: 15,
                      },
                      {
                        id: 'offset',
                        name: 'Offset',
                        defval: 0,
                        type: 'integer',
                        min: -10000,
                        max: 10000,
                        display: 15,
                      },
                      {
                        id: 'smoothingLine',
                        name: 'Smoothing Line',
                        defval: 'SMA',
                        type: 'text',
                        options: ['SMA', 'EMA', 'WMA'],
                        display: 15,
                      },
                      {
                        id: 'smoothingLength',
                        name: 'Smoothing Length',
                        defval: 9,
                        type: 'integer',
                        min: 1,
                        max: 10000,
                        display: 15,
                      },
                    ],
                    plots: [
                      { id: 'plot_0', type: 'line' },
                      { id: 'smoothedMA', type: 'line' },
                    ],
                    graphics: {},
                    defaults: {
                      styles: {
                        plot_0: {
                          display: 15,
                          linestyle: 0,
                          linewidth: 1,
                          plottype: 0,
                          trackPrice: false,
                          transparency: 0,
                          color: '#2196F3',
                        },
                        smoothedMA: {
                          display: 0,
                          linestyle: 0,
                          linewidth: 1,
                          plottype: 0,
                          trackPrice: false,
                          transparency: 0,
                        },
                      },
                      inputs: {
                        symbol: '',
                        length: 9,
                        source: 'close',
                        offset: 0,
                        smoothingLine: 'SMA',
                        smoothingLength: 9,
                      },
                    },
                    _metainfoVersion: 53,
                    isTVScript: false,
                    isTVScriptStub: false,
                    is_hidden_study: false,
                    styles: {
                      plot_0: {
                        title: 'Plot',
                        histogramBase: 0,
                        joinPoints: false,
                      },
                      smoothedMA: {
                        title: 'Smoothed MA',
                        histogramBase: 0,
                        joinPoints: false,
                      },
                    },
                    description: 'Moving Average',
                    shortDescription: 'MA',
                    is_price_study: true,
                    id: 'Moving Average@tv-basicstudies-1',
                    scriptIdPart: '',
                    name: 'Moving Average',
                    format: { type: 'inherit' },
                    symbolSource: {
                      type: 'symbolInputSymbolSource',
                      inputId: 'symbol',
                    },
                    description_localized: 'Moving Average',
                    shortId: 'Moving Average',
                    packageId: 'tv-basicstudies',
                    version: '1',
                    fullId: 'Moving Average@tv-basicstudies-1',
                    productId: 'tv-basicstudies',
                    _serverMetaInfoVersion: 52,
                  },
                },
                {
                  type: 'Study',
                  id: '3soaUC',
                  state: {
                    styles: {
                      plot_0: {
                        display: 15,
                        linestyle: 0,
                        linewidth: 2,
                        plottype: 0,
                        trackPrice: false,
                        transparency: 11,
                        color: '#7CA7EB',
                        title: 'plot_0',
                      },
                      smoothedMA: {
                        display: 0,
                        linestyle: 0,
                        linewidth: 1,
                        plottype: 0,
                        trackPrice: false,
                        transparency: 0,
                        color: '#044bff',
                        title: 'smoothedMA',
                      },
                    },
                    inputs: {
                      symbol: '',
                      length: 100,
                      source: 'close',
                      offset: 0,
                      smoothingLine: 'SMA',
                      smoothingLength: 9,
                    },
                    precision: 'default',
                    bands: {},
                    graphics: {},
                    ohlcPlots: {},
                    palettes: {},
                    filledAreasStyle: {},
                    filledAreas: {},
                    visible: true,
                    showLegendValues: true,
                    showLabelsOnPriceScale: true,
                    parentSources: {},
                    intervalsVisibilities: {
                      ticks: true,
                      seconds: true,
                      secondsFrom: 1,
                      secondsTo: 59,
                      minutes: true,
                      minutesFrom: 1,
                      minutesTo: 59,
                      hours: true,
                      hoursFrom: 1,
                      hoursTo: 24,
                      days: true,
                      daysFrom: 1,
                      daysTo: 366,
                      weeks: true,
                      weeksFrom: 1,
                      weeksTo: 52,
                      months: true,
                      monthsFrom: 1,
                      monthsTo: 12,
                      ranges: true,
                    },
                  },
                  zorder: -20000,
                  ownFirstValue: null,
                  metaInfo: {
                    palettes: {},
                    inputs: [
                      {
                        id: 'symbol',
                        name: 'Other Symbol',
                        defval: '',
                        type: 'symbol',
                        optional: true,
                        isHidden: false,
                        display: 15,
                      },
                      {
                        id: 'length',
                        name: 'Length',
                        defval: 9,
                        type: 'integer',
                        min: 1,
                        max: 10000,
                        display: 15,
                      },
                      {
                        id: 'source',
                        name: 'Source',
                        defval: 'close',
                        type: 'source',
                        options: [
                          'open',
                          'high',
                          'low',
                          'close',
                          'hl2',
                          'hlc3',
                          'ohlc4',
                        ],
                        display: 15,
                      },
                      {
                        id: 'offset',
                        name: 'Offset',
                        defval: 0,
                        type: 'integer',
                        min: -10000,
                        max: 10000,
                        display: 15,
                      },
                      {
                        id: 'smoothingLine',
                        name: 'Smoothing Line',
                        defval: 'SMA',
                        type: 'text',
                        options: ['SMA', 'EMA', 'WMA'],
                        display: 15,
                      },
                      {
                        id: 'smoothingLength',
                        name: 'Smoothing Length',
                        defval: 9,
                        type: 'integer',
                        min: 1,
                        max: 10000,
                        display: 15,
                      },
                    ],
                    plots: [
                      { id: 'plot_0', type: 'line' },
                      { id: 'smoothedMA', type: 'line' },
                    ],
                    graphics: {},
                    defaults: {
                      styles: {
                        plot_0: {
                          display: 15,
                          linestyle: 0,
                          linewidth: 1,
                          plottype: 0,
                          trackPrice: false,
                          transparency: 0,
                          color: '#2196F3',
                        },
                        smoothedMA: {
                          display: 0,
                          linestyle: 0,
                          linewidth: 1,
                          plottype: 0,
                          trackPrice: false,
                          transparency: 0,
                        },
                      },
                      inputs: {
                        symbol: '',
                        length: 9,
                        source: 'close',
                        offset: 0,
                        smoothingLine: 'SMA',
                        smoothingLength: 9,
                      },
                    },
                    _metainfoVersion: 53,
                    isTVScript: false,
                    isTVScriptStub: false,
                    is_hidden_study: false,
                    styles: {
                      plot_0: {
                        title: 'Plot',
                        histogramBase: 0,
                        joinPoints: false,
                      },
                      smoothedMA: {
                        title: 'Smoothed MA',
                        histogramBase: 0,
                        joinPoints: false,
                      },
                    },
                    description: 'Moving Average',
                    shortDescription: 'MA',
                    is_price_study: true,
                    id: 'Moving Average@tv-basicstudies-1',
                    scriptIdPart: '',
                    name: 'Moving Average',
                    format: { type: 'inherit' },
                    symbolSource: {
                      type: 'symbolInputSymbolSource',
                      inputId: 'symbol',
                    },
                    description_localized: 'Moving Average',
                    shortId: 'Moving Average',
                    packageId: 'tv-basicstudies',
                    version: '1',
                    fullId: 'Moving Average@tv-basicstudies-1',
                    productId: 'tv-basicstudies',
                    _serverMetaInfoVersion: 52,
                  },
                },
                {
                  type: 'Study',
                  id: '8F3HDM',
                  state: {
                    styles: {
                      plot_0: {
                        display: 15,
                        linestyle: 0,
                        linewidth: 2,
                        plottype: 0,
                        trackPrice: false,
                        transparency: 11,
                        color: '#3AA36D',
                        title: 'plot_0',
                      },
                      smoothedMA: {
                        display: 0,
                        linestyle: 0,
                        linewidth: 1,
                        plottype: 0,
                        trackPrice: false,
                        transparency: 0,
                        color: '#0904ff',
                        title: 'smoothedMA',
                      },
                    },
                    inputs: {
                      symbol: '',
                      length: 200,
                      source: 'close',
                      offset: 0,
                      smoothingLine: 'SMA',
                      smoothingLength: 9,
                    },
                    precision: 'default',
                    bands: {},
                    graphics: {},
                    ohlcPlots: {},
                    palettes: {},
                    filledAreasStyle: {},
                    filledAreas: {},
                    visible: true,
                    showLegendValues: true,
                    showLabelsOnPriceScale: true,
                    parentSources: {},
                    intervalsVisibilities: {
                      ticks: true,
                      seconds: true,
                      secondsFrom: 1,
                      secondsTo: 59,
                      minutes: true,
                      minutesFrom: 1,
                      minutesTo: 59,
                      hours: true,
                      hoursFrom: 1,
                      hoursTo: 24,
                      days: true,
                      daysFrom: 1,
                      daysTo: 366,
                      weeks: true,
                      weeksFrom: 1,
                      weeksTo: 52,
                      months: true,
                      monthsFrom: 1,
                      monthsTo: 12,
                      ranges: true,
                    },
                  },
                  zorder: -30000,
                  ownFirstValue: null,
                  metaInfo: {
                    palettes: {},
                    inputs: [
                      {
                        id: 'symbol',
                        name: 'Other Symbol',
                        defval: '',
                        type: 'symbol',
                        optional: true,
                        isHidden: false,
                        display: 15,
                      },
                      {
                        id: 'length',
                        name: 'Length',
                        defval: 9,
                        type: 'integer',
                        min: 1,
                        max: 10000,
                        display: 15,
                      },
                      {
                        id: 'source',
                        name: 'Source',
                        defval: 'close',
                        type: 'source',
                        options: [
                          'open',
                          'high',
                          'low',
                          'close',
                          'hl2',
                          'hlc3',
                          'ohlc4',
                        ],
                        display: 15,
                      },
                      {
                        id: 'offset',
                        name: 'Offset',
                        defval: 0,
                        type: 'integer',
                        min: -10000,
                        max: 10000,
                        display: 15,
                      },
                      {
                        id: 'smoothingLine',
                        name: 'Smoothing Line',
                        defval: 'SMA',
                        type: 'text',
                        options: ['SMA', 'EMA', 'WMA'],
                        display: 15,
                      },
                      {
                        id: 'smoothingLength',
                        name: 'Smoothing Length',
                        defval: 9,
                        type: 'integer',
                        min: 1,
                        max: 10000,
                        display: 15,
                      },
                    ],
                    plots: [
                      { id: 'plot_0', type: 'line' },
                      { id: 'smoothedMA', type: 'line' },
                    ],
                    graphics: {},
                    defaults: {
                      styles: {
                        plot_0: {
                          display: 15,
                          linestyle: 0,
                          linewidth: 1,
                          plottype: 0,
                          trackPrice: false,
                          transparency: 0,
                          color: '#2196F3',
                        },
                        smoothedMA: {
                          display: 0,
                          linestyle: 0,
                          linewidth: 1,
                          plottype: 0,
                          trackPrice: false,
                          transparency: 0,
                        },
                      },
                      inputs: {
                        symbol: '',
                        length: 9,
                        source: 'close',
                        offset: 0,
                        smoothingLine: 'SMA',
                        smoothingLength: 9,
                      },
                    },
                    _metainfoVersion: 53,
                    isTVScript: false,
                    isTVScriptStub: false,
                    is_hidden_study: false,
                    styles: {
                      plot_0: {
                        title: 'Plot',
                        histogramBase: 0,
                        joinPoints: false,
                      },
                      smoothedMA: {
                        title: 'Smoothed MA',
                        histogramBase: 0,
                        joinPoints: false,
                      },
                    },
                    description: 'Moving Average',
                    shortDescription: 'MA',
                    is_price_study: true,
                    id: 'Moving Average@tv-basicstudies-1',
                    scriptIdPart: '',
                    name: 'Moving Average',
                    format: { type: 'inherit' },
                    symbolSource: {
                      type: 'symbolInputSymbolSource',
                      inputId: 'symbol',
                    },
                    description_localized: 'Moving Average',
                    shortId: 'Moving Average',
                    packageId: 'tv-basicstudies',
                    version: '1',
                    fullId: 'Moving Average@tv-basicstudies-1',
                    productId: 'tv-basicstudies',
                    _serverMetaInfoVersion: 52,
                  },
                },
              ],
              mainSourceId: '_seriesId',
              stretchFactor: 4331.438645097106,
              leftAxisesState: [],
              rightAxisesState: [
                {
                  state: {
                    id: 'EZXBZ45NPMO6',
                    m_priceRange: null,
                    m_isAutoScale: true,
                    m_isPercentage: false,
                    m_isIndexedTo100: false,
                    m_isLog: false,
                    m_isLockScale: false,
                    m_isInverted: false,
                    m_topMargin: 0.17,
                    m_bottomMargin: 0.15,
                    alignLabels: true,
                    logFormula: { logicalOffset: 4, coordOffset: 0.0001 },
                  },
                  sources: ['_seriesId', 'P1a4zR', '3soaUC', '8F3HDM'],
                },
              ],
              overlayPriceScales: {},
              priceScaleRatio: null,
            },
            {
              sources: [
                {
                  type: 'Study',
                  id: 'poq8Ua',
                  state: {
                    styles: {
                      vol: {
                        display: 15,
                        linestyle: 0,
                        linewidth: 1,
                        plottype: 5,
                        trackPrice: false,
                        transparency: 0,
                        color: '#000080',
                        title: 'vol',
                      },
                      vol_ma: {
                        display: 15,
                        linestyle: 0,
                        linewidth: 2,
                        plottype: 0,
                        trackPrice: false,
                        transparency: 30,
                        color: '#0496ff',
                        title: 'vol_ma',
                      },
                    },
                    precision: 'default',
                    palettes: {
                      volumePalette: {
                        colors: {
                          0: {
                            color: 'rgba(235, 77, 92, 0.50)',
                            width: 1,
                            style: 0,
                          },
                          1: {
                            color: 'rgba(83, 185, 135, 0.50)',
                            width: 1,
                            style: 0,
                          },
                        },
                      },
                    },
                    inputs: { showMA: true, maLength: 20 },
                    bands: {},
                    graphics: {},
                    ohlcPlots: {},
                    filledAreasStyle: {},
                    filledAreas: {},
                    visible: true,
                    showLegendValues: true,
                    showLabelsOnPriceScale: true,
                    parentSources: {},
                    intervalsVisibilities: {
                      ticks: true,
                      seconds: true,
                      secondsFrom: 1,
                      secondsTo: 59,
                      minutes: true,
                      minutesFrom: 1,
                      minutesTo: 59,
                      hours: true,
                      hoursFrom: 1,
                      hoursTo: 24,
                      days: true,
                      daysFrom: 1,
                      daysTo: 366,
                      weeks: true,
                      weeksFrom: 1,
                      weeksTo: 52,
                      months: true,
                      monthsFrom: 1,
                      monthsTo: 12,
                      ranges: true,
                    },
                  },
                  zorder: -10000,
                  ownFirstValue: null,
                  metaInfo: {
                    palettes: {
                      volumePalette: {
                        colors: [{ name: 'Falling' }, { name: 'Growing' }],
                      },
                    },
                    inputs: [
                      {
                        id: 'showMA',
                        name: 'show MA',
                        defval: true,
                        type: 'bool',
                        display: 0,
                      },
                      {
                        id: 'maLength',
                        name: 'MA Length',
                        defval: 20,
                        type: 'integer',
                        min: 1,
                        max: 2000,
                        display: 15,
                      },
                    ],
                    plots: [
                      { id: 'vol', type: 'line' },
                      {
                        id: 'volumePalette',
                        palette: 'volumePalette',
                        target: 'vol',
                        type: 'colorer',
                      },
                      { id: 'vol_ma', type: 'line' },
                    ],
                    graphics: {},
                    defaults: {
                      styles: {
                        vol: {
                          display: 15,
                          linestyle: 0,
                          linewidth: 1,
                          plottype: 5,
                          trackPrice: false,
                          transparency: 0,
                          color: '#000080',
                        },
                        vol_ma: {
                          display: 15,
                          linestyle: 0,
                          linewidth: 2,
                          plottype: 0,
                          trackPrice: false,
                          transparency: 30,
                          color: '#0496FF',
                        },
                      },
                      precision: 0,
                      palettes: {
                        volumePalette: {
                          colors: {
                            0: {
                              color: 'rgba(235, 77, 92, 0.50)',
                              width: 1,
                              style: 0,
                            },
                            1: {
                              color: 'rgba(83, 185, 135, 0.50)',
                              width: 1,
                              style: 0,
                            },
                          },
                        },
                      },
                      inputs: { showMA: true, maLength: 20 },
                    },
                    _metainfoVersion: 53,
                    id: 'Volume_@tv-basicstudies-1',
                    is_hidden_study: false,
                    description: 'Volume_',
                    shortDescription: 'Volume',
                    is_price_study: false,
                    format: { type: 'volume', precision: 3 },
                    styles: {
                      vol: { title: 'Volume_', histogramBase: 0 },
                      vol_ma: { title: 'Volume MA', histogramBase: 0 },
                    },
                    description_localized: 'Volume_',
                    shortId: 'Volume_',
                    packageId: 'tv-basicstudies',
                    version: '1',
                    fullId: 'Volume_@tv-basicstudies-1',
                    productId: 'tv-basicstudies',
                    _serverMetaInfoVersion: 51,
                  },
                },
              ],
              mainSourceId: 'poq8Ua',
              stretchFactor: 761.6340921567289,
              leftAxisesState: [],
              rightAxisesState: [
                {
                  state: {
                    id: 'Nt7ZxxUJbdwF',
                    m_priceRange: null,
                    m_isAutoScale: true,
                    m_isPercentage: false,
                    m_isIndexedTo100: false,
                    m_isLog: false,
                    m_isLockScale: false,
                    m_isInverted: false,
                    m_topMargin: 0.17,
                    m_bottomMargin: 0.15,
                    alignLabels: true,
                    logFormula: { logicalOffset: 4, coordOffset: 0.0001 },
                  },
                  sources: ['poq8Ua'],
                },
              ],
              overlayPriceScales: {},
              priceScaleRatio: null,
            },
            {
              sources: [
                {
                  type: 'Study',
                  id: '1hnSfV',
                  state: {
                    styles: {
                      plot_0: {
                        display: 15,
                        linestyle: 0,
                        linewidth: 1,
                        plottype: 1,
                        trackPrice: false,
                        transparency: 50,
                        color: '#FF0000',
                        title: 'plot_0',
                      },
                      plot_1: {
                        display: 15,
                        linestyle: 0,
                        linewidth: 2,
                        plottype: 0,
                        trackPrice: false,
                        transparency: 11,
                        color: '#0E4DC9',
                        title: 'plot_1',
                      },
                      plot_2: {
                        display: 15,
                        linestyle: 0,
                        linewidth: 2,
                        plottype: 0,
                        trackPrice: false,
                        transparency: 11,
                        color: '#A41919',
                        title: 'plot_2',
                      },
                    },
                    palettes: {
                      palette_0: {
                        colors: {
                          0: { color: '#22AB94', width: 1, style: 0 },
                          1: { color: '#ACE5DC', width: 1, style: 0 },
                          2: { color: '#FCCBCD', width: 1, style: 0 },
                          3: { color: '#FF5252', width: 1, style: 0 },
                        },
                      },
                    },
                    inputs: { in_0: 12, in_1: 26, in_3: 'close', in_2: 9 },
                    precision: 2,
                    bands: {},
                    graphics: {},
                    ohlcPlots: {},
                    filledAreasStyle: {},
                    filledAreas: {},
                    visible: true,
                    showLegendValues: true,
                    showLabelsOnPriceScale: true,
                    parentSources: {},
                    intervalsVisibilities: {
                      ticks: true,
                      seconds: true,
                      secondsFrom: 1,
                      secondsTo: 59,
                      minutes: true,
                      minutesFrom: 1,
                      minutesTo: 59,
                      hours: true,
                      hoursFrom: 1,
                      hoursTo: 24,
                      days: true,
                      daysFrom: 1,
                      daysTo: 366,
                      weeks: true,
                      weeksFrom: 1,
                      weeksTo: 52,
                      months: true,
                      monthsFrom: 1,
                      monthsTo: 12,
                      ranges: true,
                    },
                  },
                  zorder: -10000,
                  ownFirstValue: null,
                  metaInfo: {
                    palettes: {
                      palette_0: {
                        colors: {
                          0: { name: 'Color 0' },
                          1: { name: 'Color 1' },
                          2: { name: 'Color 2' },
                          3: { name: 'Color 3' },
                        },
                      },
                    },
                    inputs: [
                      {
                        id: 'in_0',
                        name: 'fastLength',
                        defval: 12,
                        type: 'integer',
                        min: 1,
                        max: 2000,
                        display: 15,
                      },
                      {
                        id: 'in_1',
                        name: 'slowLength',
                        defval: 26,
                        type: 'integer',
                        min: 1,
                        max: 2000,
                        display: 15,
                      },
                      {
                        id: 'in_3',
                        name: 'Source',
                        defval: 'close',
                        type: 'source',
                        options: [
                          'open',
                          'high',
                          'low',
                          'close',
                          'hl2',
                          'hlc3',
                          'ohlc4',
                        ],
                        display: 15,
                      },
                      {
                        id: 'in_2',
                        name: 'signalLength',
                        defval: 9,
                        type: 'integer',
                        min: 1,
                        max: 50,
                        display: 15,
                      },
                    ],
                    plots: [
                      { id: 'plot_0', type: 'line' },
                      { id: 'plot_1', type: 'line' },
                      { id: 'plot_2', type: 'line' },
                      {
                        id: 'plot_3',
                        palette: 'palette_0',
                        target: 'plot_0',
                        type: 'colorer',
                      },
                    ],
                    graphics: {},
                    defaults: {
                      styles: {
                        plot_0: {
                          display: 15,
                          linestyle: 0,
                          linewidth: 1,
                          plottype: 5,
                          trackPrice: false,
                          transparency: 0,
                          color: '#FF5252',
                        },
                        plot_1: {
                          display: 15,
                          linestyle: 0,
                          linewidth: 1,
                          plottype: 0,
                          trackPrice: false,
                          transparency: 0,
                          color: '#2196F3',
                        },
                        plot_2: {
                          display: 15,
                          linestyle: 0,
                          linewidth: 1,
                          plottype: 0,
                          trackPrice: false,
                          transparency: 0,
                          color: '#FF6D00',
                        },
                      },
                      palettes: {
                        palette_0: {
                          colors: {
                            0: { color: '#22AB94', width: 1, style: 0 },
                            1: { color: '#ACE5DC', width: 1, style: 0 },
                            2: { color: '#FCCBCD', width: 1, style: 0 },
                            3: { color: '#FF5252', width: 1, style: 0 },
                          },
                        },
                      },
                      inputs: { in_0: 12, in_1: 26, in_3: 'close', in_2: 9 },
                    },
                    _metainfoVersion: 53,
                    isTVScript: false,
                    isTVScriptStub: false,
                    is_hidden_study: false,
                    styles: {
                      plot_0: {
                        title: 'Histogram',
                        histogramBase: 0,
                        joinPoints: false,
                      },
                      plot_1: {
                        title: 'MACD',
                        histogramBase: 0,
                        joinPoints: false,
                      },
                      plot_2: {
                        title: 'Signal',
                        histogramBase: 0,
                        joinPoints: false,
                      },
                    },
                    description: 'MACD',
                    shortDescription: 'MACD',
                    is_price_study: false,
                    id: 'Moving Average Convergence/Divergence@tv-basicstudies-1',
                    scriptIdPart: '',
                    name: 'MACD',
                    format: { type: 'inherit' },
                    description_localized: 'MACD',
                    shortId: 'Moving Average Convergence/Divergence',
                    packageId: 'tv-basicstudies',
                    version: '1',
                    fullId:
                      'Moving Average Convergence/Divergence@tv-basicstudies-1',
                    productId: 'tv-basicstudies',
                    _serverMetaInfoVersion: 52,
                  },
                },
              ],
              mainSourceId: '1hnSfV',
              stretchFactor: 543.2406327462922,
              leftAxisesState: [],
              rightAxisesState: [
                {
                  state: {
                    id: 'S6NM5pHUafj7',
                    m_priceRange: null,
                    m_isAutoScale: true,
                    m_isPercentage: false,
                    m_isIndexedTo100: false,
                    m_isLog: false,
                    m_isLockScale: false,
                    m_isInverted: false,
                    m_topMargin: 0.17,
                    m_bottomMargin: 0.15,
                    alignLabels: true,
                    logFormula: { logicalOffset: 4, coordOffset: 0.0001 },
                  },
                  sources: ['1hnSfV'],
                },
              ],
              overlayPriceScales: {},
              priceScaleRatio: null,
            },
            {
              sources: [
                {
                  type: 'Study',
                  id: '19Hbga',
                  state: {
                    styles: {
                      plot_0: {
                        display: 15,
                        linestyle: 0,
                        linewidth: 2,
                        plottype: 0,
                        trackPrice: false,
                        transparency: 11,
                        color: '#465ADB',
                        title: 'plot_0',
                      },
                      plot_1: {
                        display: 15,
                        linestyle: 0,
                        linewidth: 2,
                        plottype: 0,
                        trackPrice: false,
                        transparency: 11,
                        color: '#A41919',
                        title: 'plot_1',
                      },
                      bands: {
                        0: { color: 'rgba(152, 152, 152, 0.70)' },
                        1: { color: 'rgba(152, 152, 152, 0.70)' },
                      },
                    },
                    bands: {
                      0: {
                        color: '#787B86',
                        linestyle: 2,
                        linewidth: 1,
                        visible: true,
                        value: 80,
                      },
                      1: {
                        color: '#787B86',
                        linestyle: 2,
                        linewidth: 1,
                        visible: true,
                        value: 20,
                      },
                    },
                    filledAreasStyle: {
                      fill_0: {
                        color: '#2196F3',
                        transparency: 90,
                        visible: false,
                      },
                    },
                    inputs: { in_0: 15, in_1: 1, in_2: 3 },
                    precision: 1,
                    graphics: {},
                    ohlcPlots: {},
                    palettes: {},
                    filledAreas: {},
                    visible: true,
                    showLegendValues: true,
                    showLabelsOnPriceScale: true,
                    parentSources: {},
                    intervalsVisibilities: {
                      ticks: true,
                      seconds: true,
                      secondsFrom: 1,
                      secondsTo: 59,
                      minutes: true,
                      minutesFrom: 1,
                      minutesTo: 59,
                      hours: true,
                      hoursFrom: 1,
                      hoursTo: 24,
                      days: true,
                      daysFrom: 1,
                      daysTo: 366,
                      weeks: true,
                      weeksFrom: 1,
                      weeksTo: 52,
                      months: true,
                      monthsFrom: 1,
                      monthsTo: 12,
                      ranges: true,
                    },
                  },
                  zorder: -10000,
                  ownFirstValue: null,
                  metaInfo: {
                    palettes: {},
                    inputs: [
                      {
                        id: 'in_0',
                        name: 'K',
                        defval: 14,
                        type: 'integer',
                        min: 1,
                        max: 10000,
                        display: 15,
                      },
                      {
                        id: 'in_1',
                        name: 'D',
                        defval: 1,
                        type: 'integer',
                        min: 1,
                        max: 10000,
                        display: 15,
                      },
                      {
                        id: 'in_2',
                        name: 'smooth',
                        defval: 3,
                        type: 'integer',
                        min: 1,
                        max: 10000,
                        display: 15,
                      },
                    ],
                    plots: [
                      { id: 'plot_0', type: 'line' },
                      { id: 'plot_1', type: 'line' },
                    ],
                    graphics: {},
                    defaults: {
                      styles: {
                        plot_0: {
                          display: 15,
                          linestyle: 0,
                          linewidth: 1,
                          plottype: 0,
                          trackPrice: false,
                          transparency: 0,
                          color: '#2196F3',
                        },
                        plot_1: {
                          display: 15,
                          linestyle: 0,
                          linewidth: 1,
                          plottype: 0,
                          trackPrice: false,
                          transparency: 0,
                          color: '#FF6D00',
                        },
                      },
                      bands: [
                        {
                          color: '#787B86',
                          linestyle: 2,
                          linewidth: 1,
                          visible: true,
                          value: 80,
                        },
                        {
                          color: '#787B86',
                          linestyle: 2,
                          linewidth: 1,
                          visible: true,
                          value: 20,
                        },
                      ],
                      filledAreasStyle: {
                        fill_0: {
                          color: '#2196F3',
                          transparency: 90,
                          visible: true,
                        },
                      },
                      inputs: { in_0: 14, in_1: 1, in_2: 3 },
                    },
                    _metainfoVersion: 53,
                    isTVScript: false,
                    isTVScriptStub: false,
                    is_hidden_study: false,
                    styles: {
                      plot_0: {
                        title: '%K',
                        histogramBase: 0,
                        joinPoints: false,
                        zorder: 1.1,
                      },
                      plot_1: {
                        title: '%D',
                        histogramBase: 0,
                        joinPoints: false,
                        zorder: 1.11,
                      },
                    },
                    description: 'Stochastic',
                    shortDescription: 'Stoch',
                    is_price_study: false,
                    bands: [
                      { id: 'hline_0', name: 'UpperLimit', zorder: -1.1 },
                      { id: 'hline_1', name: 'LowerLimit', zorder: -1.11 },
                    ],
                    filledAreas: [
                      {
                        id: 'fill_0',
                        objAId: 'hline_0',
                        objBId: 'hline_1',
                        type: 'hline_hline',
                        title: 'Hlines Background',
                        zorder: -2,
                      },
                    ],
                    id: 'Stochastic@tv-basicstudies-1',
                    scriptIdPart: '',
                    name: 'Stochastic',
                    format: { precision: 2, type: 'price' },
                    usePlotsZOrder: true,
                    description_localized: 'Stochastic',
                    shortId: 'Stochastic',
                    packageId: 'tv-basicstudies',
                    version: '1',
                    fullId: 'Stochastic@tv-basicstudies-1',
                    productId: 'tv-basicstudies',
                    _serverMetaInfoVersion: 52,
                  },
                },
              ],
              mainSourceId: '19Hbga',
              stretchFactor: 601.4492753623188,
              leftAxisesState: [],
              rightAxisesState: [
                {
                  state: {
                    id: 'Otfvntv5MuNa',
                    m_priceRange: null,
                    m_isAutoScale: true,
                    m_isPercentage: false,
                    m_isIndexedTo100: false,
                    m_isLog: false,
                    m_isLockScale: false,
                    m_isInverted: false,
                    m_topMargin: 0.17,
                    m_bottomMargin: 0.15,
                    alignLabels: true,
                    logFormula: { logicalOffset: 4, coordOffset: 0.0001 },
                  },
                  sources: ['19Hbga'],
                },
              ],
              overlayPriceScales: {},
              priceScaleRatio: null,
            },
            {
              sources: [
                {
                  type: 'Study',
                  id: 'xRlWww',
                  state: {
                    styles: {
                      plot_0: {
                        display: 15,
                        linestyle: 0,
                        linewidth: 2,
                        plottype: 0,
                        trackPrice: false,
                        transparency: 11,
                        color: '#1CBECB',
                        title: 'plot_0',
                      },
                      smoothedMA: {
                        display: 0,
                        linestyle: 0,
                        linewidth: 1,
                        plottype: 0,
                        trackPrice: false,
                        transparency: 0,
                        color: '#0496ff',
                        title: 'smoothedMA',
                      },
                      bands: {
                        0: { color: 'rgba(152, 152, 152, 0.70)' },
                        1: { color: 'rgba(152, 152, 152, 0.70)' },
                      },
                    },
                    bands: {
                      0: {
                        color: '#787B86',
                        linestyle: 2,
                        linewidth: 1,
                        visible: true,
                        value: 70,
                        zorder: -1.1,
                      },
                      1: {
                        color: '#787B86',
                        linestyle: 2,
                        linewidth: 1,
                        visible: true,
                        value: 50,
                        zorder: -1.11,
                      },
                      2: {
                        color: '#787B86',
                        linestyle: 2,
                        linewidth: 1,
                        visible: true,
                        value: 30,
                        zorder: -1.111,
                      },
                    },
                    filledAreasStyle: {
                      fill_0: {
                        color: '#7E57C2',
                        transparency: 90,
                        visible: false,
                      },
                    },
                    inputs: {
                      length: 14,
                      smoothingLine: 'SMA',
                      smoothingLength: 14,
                    },
                    precision: 1,
                    graphics: {},
                    ohlcPlots: {},
                    palettes: {},
                    filledAreas: {},
                    visible: true,
                    showLegendValues: true,
                    showLabelsOnPriceScale: true,
                    parentSources: {},
                    intervalsVisibilities: {
                      ticks: true,
                      seconds: true,
                      secondsFrom: 1,
                      secondsTo: 59,
                      minutes: true,
                      minutesFrom: 1,
                      minutesTo: 59,
                      hours: true,
                      hoursFrom: 1,
                      hoursTo: 24,
                      days: true,
                      daysFrom: 1,
                      daysTo: 366,
                      weeks: true,
                      weeksFrom: 1,
                      weeksTo: 52,
                      months: true,
                      monthsFrom: 1,
                      monthsTo: 12,
                      ranges: true,
                    },
                  },
                  zorder: -10000,
                  ownFirstValue: null,
                  metaInfo: {
                    palettes: {},
                    inputs: [
                      {
                        id: 'length',
                        name: 'Length',
                        defval: 14,
                        type: 'integer',
                        min: 1,
                        max: 2000,
                        display: 15,
                      },
                      {
                        id: 'smoothingLine',
                        name: 'Smoothing Line',
                        defval: 'SMA',
                        type: 'text',
                        options: ['SMA', 'EMA', 'WMA'],
                        display: 15,
                      },
                      {
                        id: 'smoothingLength',
                        name: 'Smoothing Length',
                        defval: 14,
                        type: 'integer',
                        min: 1,
                        max: 10000,
                        display: 15,
                      },
                    ],
                    plots: [
                      { id: 'plot_0', type: 'line' },
                      { id: 'smoothedMA', type: 'line' },
                    ],
                    graphics: {},
                    defaults: {
                      styles: {
                        plot_0: {
                          display: 15,
                          linestyle: 0,
                          linewidth: 1,
                          plottype: 0,
                          trackPrice: false,
                          transparency: 0,
                          color: '#7E57C2',
                        },
                        smoothedMA: {
                          display: 0,
                          linestyle: 0,
                          linewidth: 1,
                          plottype: 0,
                          trackPrice: false,
                          transparency: 0,
                        },
                      },
                      bands: [
                        {
                          color: '#787B86',
                          linestyle: 2,
                          linewidth: 1,
                          visible: true,
                          value: 70,
                          zorder: -1.1,
                        },
                        {
                          color: '#787B86',
                          linestyle: 2,
                          linewidth: 1,
                          visible: true,
                          value: 50,
                          zorder: -1.11,
                        },
                        {
                          color: '#787B86',
                          linestyle: 2,
                          linewidth: 1,
                          visible: true,
                          value: 30,
                          zorder: -1.111,
                        },
                      ],
                      filledAreasStyle: {
                        fill_0: {
                          color: '#7E57C2',
                          transparency: 90,
                          visible: true,
                        },
                      },
                      inputs: {
                        length: 14,
                        smoothingLine: 'SMA',
                        smoothingLength: 14,
                      },
                    },
                    _metainfoVersion: 53,
                    isTVScript: false,
                    isTVScriptStub: false,
                    is_hidden_study: false,
                    styles: {
                      plot_0: {
                        title: 'Plot',
                        histogramBase: 0,
                        joinPoints: false,
                        zorder: 1,
                      },
                      smoothedMA: {
                        title: 'Smoothed MA',
                        histogramBase: 0,
                        joinPoints: false,
                        zorder: 2,
                      },
                      'Relative Strength Index@tv-basicstudies': {
                        title: 'Smoothed MA',
                        histogramBase: 0,
                        joinPoints: false,
                      },
                    },
                    description: 'Relative Strength Index',
                    shortDescription: 'RSI',
                    is_price_study: false,
                    bands: [
                      { id: 'hline_0', name: 'UpperLimit', zorder: -1.1 },
                      { id: 'hline_2', name: 'MiddleLimit', zorder: -1.11 },
                      { id: 'hline_1', name: 'LowerLimit', zorder: -1.111 },
                    ],
                    filledAreas: [
                      {
                        id: 'fill_0',
                        objAId: 'hline_0',
                        objBId: 'hline_1',
                        type: 'hline_hline',
                        title: 'Hlines Background',
                        zorder: -2,
                      },
                    ],
                    id: 'Relative Strength Index@tv-basicstudies-1',
                    scriptIdPart: '',
                    name: 'Relative Strength Index',
                    format: { precision: 2, type: 'price' },
                    usePlotsZOrder: true,
                    description_localized: 'Relative Strength Index',
                    shortId: 'Relative Strength Index',
                    packageId: 'tv-basicstudies',
                    version: '1',
                    fullId: 'Relative Strength Index@tv-basicstudies-1',
                    productId: 'tv-basicstudies',
                    _serverMetaInfoVersion: 52,
                  },
                },
              ],
              mainSourceId: 'xRlWww',
              stretchFactor: 608.695652173913,
              leftAxisesState: [],
              rightAxisesState: [
                {
                  state: {
                    id: 'h7jqfpa3lHVy',
                    m_priceRange: null,
                    m_isAutoScale: true,
                    m_isPercentage: false,
                    m_isIndexedTo100: false,
                    m_isLog: false,
                    m_isLockScale: false,
                    m_isInverted: false,
                    m_topMargin: 0.17,
                    m_bottomMargin: 0.15,
                    alignLabels: true,
                    logFormula: { logicalOffset: 4, coordOffset: 0.0001 },
                  },
                  sources: ['xRlWww'],
                },
              ],
              overlayPriceScales: {},
              priceScaleRatio: null,
            },
          ],
          timeScale: {
            m_barSpacing: 5.869312088008223,
            m_rightOffset: 10,
            rightOffsetPercentage: 5,
            usePercentageRightOffset: false,
          },
          chartProperties: {
            paneProperties: {
              backgroundType: 'solid',
              background: '#ffffff',
              backgroundGradientStartColor: '#ffffff',
              backgroundGradientEndColor: '#ffffff',
              gridLinesMode: 'both',
              vertGridProperties: { color: 'rgba(42, 46, 57, 0.06)' },
              horzGridProperties: { color: 'rgba(42, 46, 57, 0.06)' },
              crossHairProperties: {
                color: '#9598A1',
                style: 2,
                transparency: 0,
                width: 1,
                'color:': '#9598A1',
              },
              topMargin: 17,
              bottomMargin: 15,
              axisProperties: {
                autoScale: true,
                autoScaleDisabled: false,
                lockScale: false,
                percentage: false,
                percentageDisabled: false,
                indexedTo100: false,
                log: false,
                logDisabled: false,
                alignLabels: true,
                isInverted: false,
              },
              legendProperties: {
                showStudyArguments: true,
                showStudyTitles: true,
                showStudyValues: false,
                showSeriesTitle: true,
                showSeriesOHLC: true,
                showLegend: true,
                showBarChange: true,
                showVolume: false,
                showBackground: true,
                showPriceSource: true,
                backgroundTransparency: 50,
                showLogo: true,
                wrapText: false,
                showOnlyPriceSource: true,
              },
              separatorColor: '#E0E3EB',
              gridProperties: { color: '#e1ecf2', style: 0 },
              leftAxisProperties: {
                autoScale: true,
                autoScaleDisabled: false,
                lockScale: false,
                percentage: false,
                percentageDisabled: false,
                indexedTo100: false,
                log: false,
                logDisabled: false,
                alignLabels: true,
              },
              rightAxisProperties: {
                autoScale: true,
                autoScaleDisabled: false,
                lockScale: false,
                percentage: false,
                percentageDisabled: false,
                log: false,
                logDisabled: false,
                alignLabels: true,
                indexedTo100: false,
              },
              rightOffset: null,
            },
            scalesProperties: {
              backgroundColor: '#ffffff',
              lineColor: 'rgba(42, 46, 57, 0)',
              textColor: '#131722',
              fontSize: 12,
              scaleSeriesOnly: false,
              showSeriesLastValue: true,
              seriesLastValueMode: 1,
              showSeriesPrevCloseValue: false,
              showStudyLastValue: true,
              showSymbolLabels: false,
              showStudyPlotLabels: false,
              showBidAskLabels: false,
              showPrePostMarketPriceLabel: true,
              showFundamentalNameLabel: false,
              showFundamentalLastValue: true,
              barSpacing: 6,
              axisHighlightColor: 'rgba(41, 98, 255, 0.25)',
              axisLineToolLabelBackgroundColorCommon: '#2962FF',
              axisLineToolLabelBackgroundColorActive: '#143EB3',
              showPriceScaleCrosshairLabel: true,
              showTimeScaleCrosshairLabel: true,
              crosshairLabelBgColorLight: '#131722',
              crosshairLabelBgColorDark: '#363A45',
              showCurrency: true,
              showUnit: true,
              showLeftScale: false,
              showRightScale: true,
            },
            chartEventsSourceProperties: {
              visible: true,
              futureOnly: true,
              breaks: {
                color: 'rgba(85, 85, 85, 1)',
                visible: false,
                style: 2,
                width: 1,
              },
            },
            tradingProperties: {
              showPositions: true,
              positionPL: { visibility: true, display: 0 },
              bracketsPL: { visibility: true, display: 0 },
              showOrders: true,
              showExecutions: true,
              showExecutionsLabels: false,
              showReverse: true,
              horizontalAlignment: 2,
              extendLeft: true,
              lineLength: 5,
              lineWidth: 1,
              lineStyle: 0,
            },
            priceScaleSelectionStrategyName: 'auto',
          },
          sessions: {
            properties: {
              graphics: {
                backgrounds: {
                  outOfSession: {
                    color: '#2962FF',
                    transparency: 92,
                    visible: false,
                  },
                  preMarket: {
                    color: '#FF9800',
                    transparency: 92,
                    visible: false,
                  },
                  postMarket: {
                    color: '#2962FF',
                    transparency: 92,
                    visible: false,
                  },
                },
                vertlines: {
                  sessBreaks: {
                    color: '#4985e7',
                    style: 2,
                    visible: false,
                    width: 1,
                  },
                },
              },
            },
          },
          version: 3,
          timezone: 'Asia/Jakarta',
          shouldBeSavedEvenIfHidden: true,
          linkingGroup: null,
          lineToolsGroups: { groups: [] },
          chartId: '1',
          theme: 'light',
        },
      ],
      symbolLock: 0,
      intervalLock: 0,
      trackTimeLock: 0,
      dateRangeLock: 0,
      crosshairLock: 1,
      layoutsSizes: { s: [{ percent: 1 }] },
    },
  },
  {
    key: 'foreign_flow' as const,
    name: 'Foreign Flow',
    template: {
      layout: 's',
      charts: [
        {
          panes: [
            {
              sources: [
                {
                  type: 'MainSeries',
                  id: '_seriesId',
                  zorder: 0,
                  haStyle: { studyId: 'BarSetHeikenAshi@tv-basicstudies-60' },
                  renkoStyle: { studyId: 'BarSetRenko@tv-prostudies-64' },
                  pbStyle: { studyId: 'BarSetPriceBreak@tv-prostudies-34' },
                  kagiStyle: { studyId: 'BarSetKagi@tv-prostudies-34' },
                  pnfStyle: { studyId: 'BarSetPnF@tv-prostudies-34' },
                  rangeStyle: { studyId: 'BarSetRange@tv-basicstudies-72' },
                  formattingDeps: {
                    format: 'price',
                    pricescale: 1,
                    minmov: 1,
                  },
                  state: {
                    style: 1,
                    esdShowDividends: true,
                    esdShowSplits: true,
                    esdShowEarnings: true,
                    esdShowBreaks: false,
                    esdFlagSize: 2,
                    showContinuousContractSwitches: true,
                    showContinuousContractSwitchesBreaks: false,
                    showFuturesContractExpiration: true,
                    showLastNews: true,
                    showCountdown: false,
                    bidAsk: {
                      visible: false,
                      lineStyle: 1,
                      lineWidth: 1,
                      bidLineColor: '#2962FF',
                      askLineColor: '#F7525F',
                    },
                    prePostMarket: {
                      visible: true,
                      lineStyle: 1,
                      lineWidth: 1,
                      preMarketColor: '#FB8C00',
                      postMarketColor: '#2962FF',
                    },
                    highLowAvgPrice: {
                      highLowPriceLinesVisible: false,
                      highLowPriceLabelsVisible: false,
                      averageClosePriceLineVisible: false,
                      averageClosePriceLabelVisible: false,
                      highLowPriceLinesColor: '',
                      highLowPriceLinesWidth: 1,
                      averagePriceLineColor: '',
                      averagePriceLineWidth: 1,
                    },
                    visible: true,
                    showPriceLine: true,
                    priceLineWidth: 1,
                    priceLineColor: '',
                    baseLineColor: '#B2B5BE',
                    showPrevClosePriceLine: false,
                    prevClosePriceLineWidth: 1,
                    prevClosePriceLineColor: '#555555',
                    minTick: 'default',
                    dividendsAdjustment: null,
                    backAdjustment: false,
                    settlementAsClose: true,
                    sessionId: 'regular',
                    sessVis: false,
                    statusViewStyle: {
                      fontSize: 16,
                      showExchange: true,
                      showInterval: true,
                      symbolTextSource: 'description',
                      showSymbolAsDescription: false,
                    },
                    candleStyle: {
                      upColor: '#009900',
                      downColor: '#B80E0C',
                      drawWick: true,
                      drawBorder: true,
                      borderColor: '#000000',
                      borderUpColor: '#000000',
                      borderDownColor: '#000000',
                      wickColor: '#737375',
                      wickUpColor: '#000000',
                      wickDownColor: '#000000',
                      barColorsOnPrevClose: false,
                      drawBody: true,
                    },
                    hollowCandleStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      drawWick: true,
                      drawBorder: true,
                      borderColor: '#378658',
                      borderUpColor: '#089981',
                      borderDownColor: '#F23645',
                      wickColor: '#737375',
                      wickUpColor: '#089981',
                      wickDownColor: '#F23645',
                      drawBody: true,
                    },
                    haStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      drawWick: true,
                      drawBorder: true,
                      borderColor: '#378658',
                      borderUpColor: '#089981',
                      borderDownColor: '#F23645',
                      wickColor: '#737375',
                      wickUpColor: '#089981',
                      wickDownColor: '#F23645',
                      showRealLastPrice: false,
                      barColorsOnPrevClose: false,
                      inputs: {},
                      inputInfo: {},
                      drawBody: true,
                    },
                    barStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      barColorsOnPrevClose: false,
                      dontDrawOpen: false,
                      thinBars: true,
                    },
                    hiloStyle: {
                      color: '#2962FF',
                      showBorders: true,
                      borderColor: '#2962FF',
                      showLabels: true,
                      labelColor: '#2962FF',
                      drawBody: true,
                      fontSize: 7,
                    },
                    columnStyle: {
                      upColor: 'rgba(8, 153, 129, 0.5)',
                      downColor: 'rgba(242, 54, 69, 0.5)',
                      barColorsOnPrevClose: true,
                      priceSource: 'close',
                    },
                    lineStyle: {
                      color: '#2962FF',
                      linestyle: 0,
                      linewidth: 2,
                      priceSource: 'close',
                    },
                    lineWithMarkersStyle: {
                      color: '#2962FF',
                      linestyle: 0,
                      linewidth: 2,
                      priceSource: 'close',
                    },
                    steplineStyle: {
                      color: '#2962FF',
                      linestyle: 0,
                      linewidth: 2,
                      priceSource: 'close',
                    },
                    areaStyle: {
                      color1: 'rgba(41, 98, 255, 0.28)',
                      color2: '#2962FF',
                      linecolor: '#2962FF',
                      linestyle: 0,
                      linewidth: 1,
                      priceSource: 'close',
                      transparency: 100,
                    },
                    hlcAreaStyle: {
                      highLineColor: '#089981',
                      highLineStyle: 0,
                      highLineWidth: 2,
                      lowLineColor: '#F23645',
                      lowLineStyle: 0,
                      lowLineWidth: 2,
                      closeLineColor: '#868993',
                      closeLineStyle: 0,
                      closeLineWidth: 2,
                      highCloseFillColor: 'rgba(8, 153, 129, 0.2)',
                      closeLowFillColor: 'rgba(242, 54, 69, 0.2)',
                    },
                    renkoStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      borderUpColor: '#089981',
                      borderDownColor: '#F23645',
                      upColorProjection: '#a9dcc3',
                      downColorProjection: '#f5a6ae',
                      borderUpColorProjection: '#a9dcc3',
                      borderDownColorProjection: '#f5a6ae',
                      wickUpColor: '#089981',
                      wickDownColor: '#F23645',
                      inputs: {
                        source: 'close',
                        sources: 'Close',
                        boxSize: 3,
                        style: 'ATR',
                        atrLength: 14,
                        wicks: true,
                      },
                      inputInfo: {
                        source: { name: 'Source' },
                        sources: { name: 'Source' },
                        boxSize: { name: 'Box size' },
                        style: { name: 'Style' },
                        atrLength: { name: 'ATR length' },
                        wicks: { name: 'Wicks' },
                      },
                    },
                    pbStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      borderUpColor: '#089981',
                      borderDownColor: '#F23645',
                      upColorProjection: '#a9dcc3',
                      downColorProjection: '#f5a6ae',
                      borderUpColorProjection: '#a9dcc3',
                      borderDownColorProjection: '#f5a6ae',
                      inputs: { source: 'close', lb: 3 },
                      inputInfo: {
                        source: { name: 'Source' },
                        lb: { name: 'Number of line' },
                      },
                    },
                    kagiStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      upColorProjection: '#a9dcc3',
                      downColorProjection: '#f5a6ae',
                      inputs: {
                        source: 'close',
                        style: 'ATR',
                        atrLength: 14,
                        reversalAmount: 1,
                      },
                      inputInfo: {
                        source: { name: 'Source' },
                        style: { name: 'Style' },
                        atrLength: { name: 'ATR length' },
                        reversalAmount: { name: 'Reversal amount' },
                      },
                    },
                    pnfStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      upColorProjection: '#a9dcc3',
                      downColorProjection: '#f5a6ae',
                      inputs: {
                        sources: 'Close',
                        reversalAmount: 3,
                        boxSize: 1,
                        style: 'ATR',
                        atrLength: 14,
                        oneStepBackBuilding: false,
                      },
                      inputInfo: {
                        sources: { name: 'Source' },
                        boxSize: { name: 'Box size' },
                        reversalAmount: { name: 'Reversal amount' },
                        style: { name: 'Style' },
                        atrLength: { name: 'ATR length' },
                        oneStepBackBuilding: { name: 'One step back building' },
                      },
                    },
                    baselineStyle: {
                      baselineColor: '#758696',
                      topFillColor1: 'rgba(8, 153, 129, 0.28)',
                      topFillColor2: 'rgba(8, 153, 129, 0.05)',
                      bottomFillColor1: 'rgba(242, 54, 69, 0.05)',
                      bottomFillColor2: 'rgba(242, 54, 69, 0.28)',
                      topLineColor: '#089981',
                      bottomLineColor: '#F23645',
                      topLineWidth: 1,
                      bottomLineWidth: 1,
                      priceSource: 'close',
                      transparency: 50,
                      baseLevelPercentage: 50,
                    },
                    rangeStyle: {
                      barStyle: 0,
                      upColor: '#089981',
                      downColor: '#F23645',
                      upColorProjection: '#a9dcc3',
                      downColorProjection: '#f5a6ae',
                      thinBars: true,
                      candlesUpColor: '#089981',
                      candlesDownColor: '#F23645',
                      candlesBorderUpColor: '#089981',
                      candlesBorderDownColor: '#F23645',
                      candlesWickUpColor: '#089981',
                      candlesWickDownColor: '#F23645',
                      inputs: { range: 10, phantomBars: false },
                      inputInfo: {
                        range: { name: 'Range' },
                        phantomBars: { name: 'Phantom bars' },
                      },
                    },
                    showInDataWindow: true,
                    symbol: 'BBCA',
                    shortName: 'BBCA',
                    timeframe: '',
                    onWidget: false,
                    interval: 'D',
                    unitId: null,
                    showSessions: false,
                    shortname: '',
                    currencyId: null,
                    priceAxisProperties: {
                      autoScale: true,
                      autoScaleDisabled: false,
                      lockScale: false,
                      percentage: false,
                      percentageDisabled: false,
                      log: false,
                      logDisabled: false,
                      alignLabels: true,
                      isInverted: false,
                      indexedTo100: false,
                    },
                  },
                },
                {
                  type: 'Study',
                  id: 'z5XxkQ',
                  state: {
                    styles: {
                      plot_0: {
                        display: 15,
                        linestyle: 0,
                        linewidth: 2,
                        plottype: 0,
                        trackPrice: false,
                        transparency: 11,
                        color: '#5F72EC',
                        title: 'plot_0',
                      },
                    },
                    precision: 'default',
                    inputs: {},
                    bands: {},
                    graphics: {},
                    ohlcPlots: {},
                    palettes: {},
                    filledAreasStyle: {},
                    filledAreas: {},
                    visible: true,
                    showLegendValues: true,
                    showLabelsOnPriceScale: true,
                    parentSources: {},
                    intervalsVisibilities: {
                      ticks: true,
                      seconds: true,
                      secondsFrom: 1,
                      secondsTo: 59,
                      minutes: true,
                      minutesFrom: 1,
                      minutesTo: 59,
                      hours: true,
                      hoursFrom: 1,
                      hoursTo: 24,
                      days: true,
                      daysFrom: 1,
                      daysTo: 366,
                      weeks: true,
                      weeksFrom: 1,
                      weeksTo: 52,
                      months: true,
                      monthsFrom: 1,
                      monthsTo: 12,
                      ranges: true,
                    },
                  },
                  zorder: -10000,
                  ownFirstValue: null,
                  metaInfo: {
                    palettes: {},
                    inputs: [],
                    plots: [{ id: 'plot_0', type: 'line' }],
                    graphics: {},
                    defaults: {
                      styles: {
                        plot_0: {
                          display: 15,
                          linestyle: 0,
                          linewidth: 2,
                          plottype: 0,
                          trackPrice: false,
                          transparency: 11,
                          color: '#5F72EC',
                        },
                      },
                      precision: 0,
                      inputs: {},
                    },
                    _metainfoVersion: 53,
                    id: 'Foreign Flow Underlay@tv-basicstudies-1',
                    name: 'Foreign Flow Underlay',
                    description: 'Foreign Flow Underlay',
                    shortDescription: 'Foreign Flow Underlay',
                    is_hidden_study: false,
                    is_price_study: true,
                    isCustomIndicator: true,
                    format: { type: 'volume', precision: 3 },
                    styles: {
                      plot_0: {
                        title: 'Foreign Flow Underlay value',
                        histogramBase: 0,
                      },
                    },
                    description_localized: 'Foreign Flow Underlay',
                    shortId: 'Foreign Flow Underlay',
                    packageId: 'tv-basicstudies',
                    version: '1',
                    fullId: 'Foreign Flow Underlay@tv-basicstudies-1',
                    productId: 'tv-basicstudies',
                    _serverMetaInfoVersion: 51,
                  },
                },
              ],
              mainSourceId: '_seriesId',
              stretchFactor: 3084.82420537963,
              leftAxisesState: [],
              rightAxisesState: [
                {
                  state: {
                    id: 'WZB8dP50LJ7p',
                    m_priceRange: null,
                    m_isAutoScale: true,
                    m_isPercentage: false,
                    m_isIndexedTo100: false,
                    m_isLog: false,
                    m_isLockScale: false,
                    m_isInverted: false,
                    m_topMargin: 0.17,
                    m_bottomMargin: 0.15,
                    alignLabels: true,
                    logFormula: { logicalOffset: 4, coordOffset: 0.0001 },
                  },
                  sources: ['_seriesId'],
                },
              ],
              overlayPriceScales: {
                z5XxkQ: {
                  id: 'duRWXItYdRlD',
                  m_priceRange: null,
                  m_isAutoScale: true,
                  m_isPercentage: false,
                  m_isIndexedTo100: false,
                  m_isLog: false,
                  m_isLockScale: false,
                  m_isInverted: false,
                  m_topMargin: 0.17,
                  m_bottomMargin: 0.15,
                  alignLabels: true,
                  logFormula: { logicalOffset: 4, coordOffset: 0.0001 },
                },
              },
              priceScaleRatio: null,
            },
            {
              sources: [
                {
                  type: 'Study',
                  id: '1wSsej',
                  state: {
                    styles: {
                      vol: {
                        display: 15,
                        linestyle: 0,
                        linewidth: 1,
                        plottype: 5,
                        trackPrice: false,
                        transparency: 0,
                        color: '#000080',
                        title: 'vol',
                      },
                      vol_ma: {
                        display: 15,
                        linestyle: 0,
                        linewidth: 2,
                        plottype: 0,
                        trackPrice: false,
                        transparency: 30,
                        color: '#0496ff',
                        title: 'vol_ma',
                      },
                    },
                    precision: 'default',
                    palettes: {
                      volumePalette: {
                        colors: {
                          0: {
                            color: 'rgba(235, 77, 92, 0.50)',
                            width: 1,
                            style: 0,
                          },
                          1: {
                            color: 'rgba(83, 185, 135, 0.50)',
                            width: 1,
                            style: 0,
                          },
                        },
                      },
                    },
                    inputs: { showMA: true, maLength: 20 },
                    bands: {},
                    graphics: {},
                    ohlcPlots: {},
                    filledAreasStyle: {},
                    filledAreas: {},
                    visible: true,
                    showLegendValues: true,
                    showLabelsOnPriceScale: true,
                    parentSources: {},
                    intervalsVisibilities: {
                      ticks: true,
                      seconds: true,
                      secondsFrom: 1,
                      secondsTo: 59,
                      minutes: true,
                      minutesFrom: 1,
                      minutesTo: 59,
                      hours: true,
                      hoursFrom: 1,
                      hoursTo: 24,
                      days: true,
                      daysFrom: 1,
                      daysTo: 366,
                      weeks: true,
                      weeksFrom: 1,
                      weeksTo: 52,
                      months: true,
                      monthsFrom: 1,
                      monthsTo: 12,
                      ranges: true,
                    },
                  },
                  zorder: -10000,
                  ownFirstValue: null,
                  metaInfo: {
                    palettes: {
                      volumePalette: {
                        colors: [{ name: 'Falling' }, { name: 'Growing' }],
                      },
                    },
                    inputs: [
                      {
                        id: 'showMA',
                        name: 'show MA',
                        defval: true,
                        type: 'bool',
                        display: 0,
                      },
                      {
                        id: 'maLength',
                        name: 'MA Length',
                        defval: 20,
                        type: 'integer',
                        min: 1,
                        max: 2000,
                        display: 15,
                      },
                    ],
                    plots: [
                      { id: 'vol', type: 'line' },
                      {
                        id: 'volumePalette',
                        palette: 'volumePalette',
                        target: 'vol',
                        type: 'colorer',
                      },
                      { id: 'vol_ma', type: 'line' },
                    ],
                    graphics: {},
                    defaults: {
                      styles: {
                        vol: {
                          display: 15,
                          linestyle: 0,
                          linewidth: 1,
                          plottype: 5,
                          trackPrice: false,
                          transparency: 0,
                          color: '#000080',
                        },
                        vol_ma: {
                          display: 15,
                          linestyle: 0,
                          linewidth: 2,
                          plottype: 0,
                          trackPrice: false,
                          transparency: 30,
                          color: '#0496FF',
                        },
                      },
                      precision: 0,
                      palettes: {
                        volumePalette: {
                          colors: {
                            0: {
                              color: 'rgba(235, 77, 92, 0.50)',
                              width: 1,
                              style: 0,
                            },
                            1: {
                              color: 'rgba(83, 185, 135, 0.50)',
                              width: 1,
                              style: 0,
                            },
                          },
                        },
                      },
                      inputs: { showMA: true, maLength: 20 },
                    },
                    _metainfoVersion: 53,
                    id: 'Volume_@tv-basicstudies-1',
                    is_hidden_study: false,
                    description: 'Volume_',
                    shortDescription: 'Volume',
                    is_price_study: false,
                    format: { type: 'volume', precision: 3 },
                    styles: {
                      vol: { title: 'Volume_', histogramBase: 0 },
                      vol_ma: { title: 'Volume MA', histogramBase: 0 },
                    },
                    description_localized: 'Volume_',
                    shortId: 'Volume_',
                    packageId: 'tv-basicstudies',
                    version: '1',
                    fullId: 'Volume_@tv-basicstudies-1',
                    productId: 'tv-basicstudies',
                    _serverMetaInfoVersion: 51,
                  },
                },
              ],
              mainSourceId: '1wSsej',
              stretchFactor: 761.6340921567289,
              leftAxisesState: [],
              rightAxisesState: [
                {
                  state: {
                    id: 'u9NUxWMF044v',
                    m_priceRange: null,
                    m_isAutoScale: true,
                    m_isPercentage: false,
                    m_isIndexedTo100: false,
                    m_isLog: false,
                    m_isLockScale: false,
                    m_isInverted: false,
                    m_topMargin: 0.17,
                    m_bottomMargin: 0.15,
                    alignLabels: true,
                    logFormula: { logicalOffset: 4, coordOffset: 0.0001 },
                  },
                  sources: ['1wSsej'],
                },
              ],
              overlayPriceScales: {},
              priceScaleRatio: null,
            },
            {
              sources: [
                {
                  type: 'Study',
                  id: 'HJwr9F',
                  state: {
                    styles: {
                      plot_0: {
                        display: 15,
                        linestyle: 0,
                        linewidth: 2,
                        plottype: 0,
                        trackPrice: false,
                        transparency: 11,
                        color: '#BE5FEC',
                        title: 'plot_0',
                      },
                    },
                    precision: 'default',
                    inputs: {},
                    bands: {},
                    graphics: {},
                    ohlcPlots: {},
                    palettes: {},
                    filledAreasStyle: {},
                    filledAreas: {},
                    visible: true,
                    showLegendValues: true,
                    showLabelsOnPriceScale: true,
                    parentSources: {},
                    intervalsVisibilities: {
                      ticks: true,
                      seconds: true,
                      secondsFrom: 1,
                      secondsTo: 59,
                      minutes: true,
                      minutesFrom: 1,
                      minutesTo: 59,
                      hours: true,
                      hoursFrom: 1,
                      hoursTo: 24,
                      days: true,
                      daysFrom: 1,
                      daysTo: 366,
                      weeks: true,
                      weeksFrom: 1,
                      weeksTo: 52,
                      months: true,
                      monthsFrom: 1,
                      monthsTo: 12,
                      ranges: true,
                    },
                  },
                  zorder: -10000,
                  ownFirstValue: null,
                  metaInfo: {
                    palettes: {},
                    inputs: [],
                    plots: [{ id: 'plot_0', type: 'line' }],
                    graphics: {},
                    defaults: {
                      styles: {
                        plot_0: {
                          display: 15,
                          linestyle: 0,
                          linewidth: 2,
                          plottype: 0,
                          trackPrice: false,
                          transparency: 11,
                          color: '#BE5FEC',
                        },
                      },
                      precision: 0,
                      inputs: {},
                    },
                    _metainfoVersion: 53,
                    id: 'Foreign Flow@tv-basicstudies-1',
                    name: 'Foreign Flow',
                    description: 'Foreign Flow',
                    shortDescription: 'Foreign Flow',
                    is_hidden_study: false,
                    is_price_study: false,
                    isCustomIndicator: true,
                    format: { type: 'volume', precision: 3 },
                    styles: {
                      plot_0: {
                        title: 'Foreign Flow value',
                        histogramBase: 0,
                      },
                    },
                    description_localized: 'Foreign Flow',
                    shortId: 'Foreign Flow',
                    packageId: 'tv-basicstudies',
                    version: '1',
                    fullId: 'Foreign Flow@tv-basicstudies-1',
                    productId: 'tv-basicstudies',
                    _serverMetaInfoVersion: 51,
                  },
                },
              ],
              mainSourceId: 'HJwr9F',
              stretchFactor: 624.0973825912547,
              leftAxisesState: [],
              rightAxisesState: [
                {
                  state: {
                    id: 'SGv9ypZYDeGF',
                    m_priceRange: null,
                    m_isAutoScale: true,
                    m_isPercentage: false,
                    m_isIndexedTo100: false,
                    m_isLog: false,
                    m_isLockScale: false,
                    m_isInverted: false,
                    m_topMargin: 0.17,
                    m_bottomMargin: 0.15,
                    alignLabels: true,
                    logFormula: { logicalOffset: 4, coordOffset: 0.0001 },
                  },
                  sources: ['HJwr9F'],
                },
              ],
              overlayPriceScales: {},
              priceScaleRatio: null,
            },
            {
              sources: [
                {
                  type: 'Study',
                  id: 'sEAGzL',
                  state: {
                    palettes: {
                      palette_0: {
                        colors: {
                          0: { color: '#B80E0C', width: 5, style: 0 },
                          1: { color: '#009900', width: 5, style: 0 },
                        },
                      },
                    },
                    styles: {
                      plot_0: {
                        display: 15,
                        linestyle: 0,
                        linewidth: 5,
                        plottype: 5,
                        trackPrice: false,
                        transparency: 30,
                        color: '#009900',
                        title: 'plot_0',
                      },
                    },
                    precision: 'default',
                    inputs: {},
                    bands: {},
                    graphics: {},
                    ohlcPlots: {},
                    filledAreasStyle: {},
                    filledAreas: {},
                    visible: true,
                    showLegendValues: true,
                    showLabelsOnPriceScale: true,
                    parentSources: {},
                    intervalsVisibilities: {
                      ticks: true,
                      seconds: true,
                      secondsFrom: 1,
                      secondsTo: 59,
                      minutes: true,
                      minutesFrom: 1,
                      minutesTo: 59,
                      hours: true,
                      hoursFrom: 1,
                      hoursTo: 24,
                      days: true,
                      daysFrom: 1,
                      daysTo: 366,
                      weeks: true,
                      weeksFrom: 1,
                      weeksTo: 52,
                      months: true,
                      monthsFrom: 1,
                      monthsTo: 12,
                      ranges: true,
                    },
                  },
                  zorder: -10000,
                  ownFirstValue: null,
                  metaInfo: {
                    palettes: {
                      palette_0: {
                        colors: [{ name: 'Color 0' }, { name: 'Color 1' }],
                      },
                    },
                    inputs: [],
                    plots: [
                      { id: 'plot_0', type: 'line' },
                      {
                        id: 'plot_1',
                        palette: 'palette_0',
                        target: 'plot_0',
                        type: 'colorer',
                      },
                    ],
                    graphics: {},
                    defaults: {
                      palettes: {
                        palette_0: {
                          colors: [
                            { color: '#B80E0C', width: 5, style: 0 },
                            { color: '#009900', width: 5, style: 0 },
                          ],
                        },
                      },
                      styles: {
                        plot_0: {
                          display: 15,
                          linestyle: 0,
                          linewidth: 5,
                          plottype: 5,
                          trackPrice: false,
                          transparency: 30,
                          color: '#009900',
                        },
                      },
                      precision: 0,
                      inputs: {},
                    },
                    _metainfoVersion: 53,
                    id: 'Net Foreign Buy / Sell@tv-basicstudies-12',
                    name: 'Net Foreign Buy / Sell',
                    description: 'Net Foreign Buy / Sell',
                    shortDescription: 'Net Foreign Buy / Sell',
                    is_hidden_study: false,
                    is_price_study: false,
                    isCustomIndicator: true,
                    format: { type: 'volume', precision: 3 },
                    styles: {
                      plot_0: {
                        title: 'Net Foreign Buy / Sell value',
                        histogramBase: 0,
                        joinPoints: false,
                      },
                    },
                    description_localized: 'Net Foreign Buy / Sell',
                    shortId: 'Net Foreign Buy / Sell',
                    packageId: 'tv-basicstudies',
                    version: '12',
                    fullId: 'Net Foreign Buy / Sell@tv-basicstudies-12',
                    productId: 'tv-basicstudies',
                    _serverMetaInfoVersion: 51,
                  },
                },
              ],
              mainSourceId: 'sEAGzL',
              stretchFactor: 642.5084855766199,
              leftAxisesState: [],
              rightAxisesState: [
                {
                  state: {
                    id: 'qZdE2NiKmlfh',
                    m_priceRange: null,
                    m_isAutoScale: true,
                    m_isPercentage: false,
                    m_isIndexedTo100: false,
                    m_isLog: false,
                    m_isLockScale: false,
                    m_isInverted: false,
                    m_topMargin: 0.17,
                    m_bottomMargin: 0.15,
                    alignLabels: true,
                    logFormula: { logicalOffset: 4, coordOffset: 0.0001 },
                  },
                  sources: ['sEAGzL'],
                },
              ],
              overlayPriceScales: {},
              priceScaleRatio: null,
            },
            {
              sources: [
                {
                  type: 'Study',
                  id: 'wt9yiH',
                  state: {
                    filledAreasStyle: {
                      strongArea: {
                        color: '#009900',
                        transparency: 80,
                        visible: true,
                      },
                      weakArea: {
                        color: '#B80E0C',
                        transparency: 80,
                        visible: true,
                      },
                    },
                    styles: {
                      main: {
                        display: 15,
                        plottype: 0,
                        color: '#333333',
                        linewidth: 2,
                        transparency: 50,
                        linestyle: 0,
                        trackPrice: false,
                        title: 'main',
                      },
                      strong: {
                        display: 15,
                        plottype: 0,
                        color: '#b80e0c',
                        transparency: 80,
                        linewidth: 1,
                        linestyle: 0,
                        trackPrice: false,
                        title: 'strong',
                      },
                      weak: {
                        display: 15,
                        plottype: 0,
                        color: '#b80e0c',
                        transparency: 80,
                        linewidth: 1,
                        linestyle: 0,
                        trackPrice: false,
                        title: 'weak',
                      },
                    },
                    precision: 'default',
                    inputs: {
                      showZones: true,
                      period: 50,
                      strong: 20,
                      weak: 20,
                    },
                    bands: {},
                    graphics: {},
                    ohlcPlots: {},
                    palettes: {},
                    filledAreas: {},
                    visible: true,
                    showLegendValues: true,
                    showLabelsOnPriceScale: true,
                    parentSources: {},
                    intervalsVisibilities: {
                      ticks: true,
                      seconds: true,
                      secondsFrom: 1,
                      secondsTo: 59,
                      minutes: true,
                      minutesFrom: 1,
                      minutesTo: 59,
                      hours: true,
                      hoursFrom: 1,
                      hoursTo: 24,
                      days: true,
                      daysFrom: 1,
                      daysTo: 366,
                      weeks: true,
                      weeksFrom: 1,
                      weeksTo: 52,
                      months: true,
                      monthsFrom: 1,
                      monthsTo: 12,
                      ranges: true,
                    },
                  },
                  zorder: -10000,
                  ownFirstValue: null,
                  metaInfo: {
                    palettes: {},
                    inputs: [
                      {
                        id: 'showZones',
                        name: 'Show Zones',
                        defval: true,
                        type: 'bool',
                        display: 0,
                      },
                      {
                        id: 'period',
                        name: 'Period',
                        defval: 50,
                        type: 'integer',
                        min: 1,
                        max: 500,
                        display: 15,
                      },
                      {
                        id: 'strong',
                        name: 'Strong',
                        defval: 20,
                        type: 'integer',
                        min: 1,
                        max: 500,
                        display: 15,
                      },
                      {
                        id: 'weak',
                        name: 'Weak',
                        defval: 20,
                        type: 'integer',
                        min: 1,
                        max: 500,
                        display: 15,
                      },
                    ],
                    plots: [
                      { id: 'main', type: 'line' },
                      { id: 'strong', type: 'line' },
                      { id: 'weak', type: 'line' },
                    ],
                    graphics: {},
                    defaults: {
                      filledAreasStyle: {
                        strongArea: {
                          color: '#009900',
                          transparency: 80,
                          visible: true,
                        },
                        weakArea: {
                          color: '#B80E0C',
                          transparency: 80,
                          visible: true,
                        },
                      },
                      styles: {
                        main: {
                          display: 15,
                          plottype: 0,
                          color: '#333333',
                          linewidth: 2,
                          transparency: 50,
                        },
                        strong: {
                          display: 15,
                          plottype: 0,
                          color: '#B80E0C',
                          transparency: 80,
                          linewidth: 1,
                        },
                        weak: {
                          display: 15,
                          plottype: 0,
                          color: '#B80E0C',
                          transparency: 80,
                          linewidth: 1,
                        },
                      },
                      precision: 2,
                      inputs: {
                        showZones: true,
                        period: 50,
                        strong: 20,
                        weak: 20,
                      },
                    },
                    _metainfoVersion: 53,
                    id: 'Foreign Strength@tv-basicstudies-1',
                    name: 'Foreign Strength',
                    description: 'Foreign Strength',
                    shortDescription: 'Foreign Strength',
                    is_price_study: false,
                    isCustomIndicator: true,
                    format: { type: 'price', precision: 2 },
                    filledAreas: [
                      {
                        id: 'strongArea',
                        objAId: 'main',
                        objBId: 'strong',
                        type: 'plot_plot',
                        title: 'Strong Background',
                      },
                      {
                        id: 'weakArea',
                        objAId: 'main',
                        objBId: 'weak',
                        type: 'plot_plot',
                        title: 'Weak Background',
                      },
                    ],
                    styles: {
                      main: { title: 'Foreign Strength' },
                      strong: { title: 'Strong' },
                      weak: { title: 'Weak' },
                    },
                    description_localized: 'Foreign Strength',
                    shortId: 'Foreign Strength',
                    packageId: 'tv-basicstudies',
                    version: '1',
                    fullId: 'Foreign Strength@tv-basicstudies-1',
                    productId: 'tv-basicstudies',
                    _serverMetaInfoVersion: 51,
                  },
                },
              ],
              mainSourceId: 'wt9yiH',
              stretchFactor: 648.5699264524955,
              leftAxisesState: [],
              rightAxisesState: [
                {
                  state: {
                    id: 'KgncAbvd9mxY',
                    m_priceRange: null,
                    m_isAutoScale: true,
                    m_isPercentage: false,
                    m_isIndexedTo100: false,
                    m_isLog: false,
                    m_isLockScale: false,
                    m_isInverted: false,
                    m_topMargin: 0.17,
                    m_bottomMargin: 0.15,
                    alignLabels: true,
                    logFormula: { logicalOffset: 4, coordOffset: 0.0001 },
                  },
                  sources: ['wt9yiH'],
                },
              ],
              overlayPriceScales: {},
              priceScaleRatio: null,
            },
          ],
          timeScale: {
            m_barSpacing: 6,
            m_rightOffset: 10,
            rightOffsetPercentage: 5,
            usePercentageRightOffset: false,
          },
          chartProperties: {
            paneProperties: {
              backgroundType: 'solid',
              background: '#ffffff',
              backgroundGradientStartColor: '#ffffff',
              backgroundGradientEndColor: '#ffffff',
              gridLinesMode: 'both',
              vertGridProperties: { color: 'rgba(42, 46, 57, 0.06)' },
              horzGridProperties: { color: 'rgba(42, 46, 57, 0.06)' },
              crossHairProperties: {
                color: '#9598A1',
                style: 2,
                transparency: 0,
                width: 1,
                'color:': '#9598A1',
              },
              topMargin: 17,
              bottomMargin: 15,
              axisProperties: {
                autoScale: true,
                autoScaleDisabled: false,
                lockScale: false,
                percentage: false,
                percentageDisabled: false,
                indexedTo100: false,
                log: false,
                logDisabled: false,
                alignLabels: true,
                isInverted: false,
              },
              legendProperties: {
                showStudyArguments: true,
                showStudyTitles: true,
                showStudyValues: false,
                showSeriesTitle: true,
                showSeriesOHLC: true,
                showLegend: true,
                showBarChange: true,
                showVolume: false,
                showBackground: true,
                showPriceSource: true,
                backgroundTransparency: 50,
                showLogo: true,
                wrapText: false,
                showOnlyPriceSource: true,
              },
              separatorColor: '#E0E3EB',
              gridProperties: { color: '#e1ecf2', style: 0 },
              leftAxisProperties: {
                autoScale: true,
                autoScaleDisabled: false,
                lockScale: false,
                percentage: false,
                percentageDisabled: false,
                indexedTo100: false,
                log: false,
                logDisabled: false,
                alignLabels: true,
              },
              rightAxisProperties: {
                autoScale: true,
                autoScaleDisabled: false,
                lockScale: false,
                percentage: false,
                percentageDisabled: false,
                log: false,
                logDisabled: false,
                alignLabels: true,
                indexedTo100: false,
              },
              rightOffset: null,
            },
            scalesProperties: {
              backgroundColor: '#ffffff',
              lineColor: 'rgba(42, 46, 57, 0)',
              textColor: '#131722',
              fontSize: 12,
              scaleSeriesOnly: false,
              showSeriesLastValue: true,
              seriesLastValueMode: 1,
              showSeriesPrevCloseValue: false,
              showStudyLastValue: true,
              showSymbolLabels: false,
              showStudyPlotLabels: false,
              showBidAskLabels: false,
              showPrePostMarketPriceLabel: true,
              showFundamentalNameLabel: false,
              showFundamentalLastValue: true,
              barSpacing: 6,
              axisHighlightColor: 'rgba(41, 98, 255, 0.25)',
              axisLineToolLabelBackgroundColorCommon: '#2962FF',
              axisLineToolLabelBackgroundColorActive: '#143EB3',
              showPriceScaleCrosshairLabel: true,
              showTimeScaleCrosshairLabel: true,
              crosshairLabelBgColorLight: '#131722',
              crosshairLabelBgColorDark: '#363A45',
              showCurrency: true,
              showUnit: true,
              showLeftScale: false,
              showRightScale: true,
            },
            chartEventsSourceProperties: {
              visible: true,
              futureOnly: true,
              breaks: {
                color: 'rgba(85, 85, 85, 1)',
                visible: false,
                style: 2,
                width: 1,
              },
            },
            tradingProperties: {
              showPositions: true,
              positionPL: { visibility: true, display: 0 },
              bracketsPL: { visibility: true, display: 0 },
              showOrders: true,
              showExecutions: true,
              showExecutionsLabels: false,
              showReverse: true,
              horizontalAlignment: 2,
              extendLeft: true,
              lineLength: 5,
              lineWidth: 1,
              lineStyle: 0,
            },
            priceScaleSelectionStrategyName: 'auto',
          },
          sessions: {
            properties: {
              graphics: {
                backgrounds: {
                  outOfSession: {
                    color: '#2962FF',
                    transparency: 92,
                    visible: false,
                  },
                  preMarket: {
                    color: '#FF9800',
                    transparency: 92,
                    visible: false,
                  },
                  postMarket: {
                    color: '#2962FF',
                    transparency: 92,
                    visible: false,
                  },
                },
                vertlines: {
                  sessBreaks: {
                    color: '#4985e7',
                    style: 2,
                    visible: false,
                    width: 1,
                  },
                },
              },
            },
          },
          version: 3,
          timezone: 'Asia/Jakarta',
          shouldBeSavedEvenIfHidden: true,
          linkingGroup: null,
          lineToolsGroups: { groups: [] },
          chartId: '1',
          theme: 'light',
        },
      ],
      symbolLock: 0,
      intervalLock: 0,
      trackTimeLock: 0,
      dateRangeLock: 0,
      crosshairLock: 1,
      layoutsSizes: { s: [{ percent: 1 }] },
    },
  },
  {
    key: 'bandarmology' as const,
    name: 'Bandarmology',
    template: {
      layout: 's',
      charts: [
        {
          panes: [
            {
              sources: [
                {
                  type: 'MainSeries',
                  id: '_seriesId',
                  zorder: 0,
                  haStyle: { studyId: 'BarSetHeikenAshi@tv-basicstudies-60' },
                  renkoStyle: { studyId: 'BarSetRenko@tv-prostudies-64' },
                  pbStyle: { studyId: 'BarSetPriceBreak@tv-prostudies-34' },
                  kagiStyle: { studyId: 'BarSetKagi@tv-prostudies-34' },
                  pnfStyle: { studyId: 'BarSetPnF@tv-prostudies-34' },
                  rangeStyle: { studyId: 'BarSetRange@tv-basicstudies-72' },
                  formattingDeps: {
                    format: 'price',
                    pricescale: 1,
                    minmov: 1,
                  },
                  state: {
                    style: 1,
                    esdShowDividends: true,
                    esdShowSplits: true,
                    esdShowEarnings: true,
                    esdShowBreaks: false,
                    esdFlagSize: 2,
                    showContinuousContractSwitches: true,
                    showContinuousContractSwitchesBreaks: false,
                    showFuturesContractExpiration: true,
                    showLastNews: true,
                    showCountdown: false,
                    bidAsk: {
                      visible: false,
                      lineStyle: 1,
                      lineWidth: 1,
                      bidLineColor: '#2962FF',
                      askLineColor: '#F7525F',
                    },
                    prePostMarket: {
                      visible: true,
                      lineStyle: 1,
                      lineWidth: 1,
                      preMarketColor: '#FB8C00',
                      postMarketColor: '#2962FF',
                    },
                    highLowAvgPrice: {
                      highLowPriceLinesVisible: false,
                      highLowPriceLabelsVisible: false,
                      averageClosePriceLineVisible: false,
                      averageClosePriceLabelVisible: false,
                      highLowPriceLinesColor: '',
                      highLowPriceLinesWidth: 1,
                      averagePriceLineColor: '',
                      averagePriceLineWidth: 1,
                    },
                    visible: true,
                    showPriceLine: true,
                    priceLineWidth: 1,
                    priceLineColor: '',
                    baseLineColor: '#B2B5BE',
                    showPrevClosePriceLine: false,
                    prevClosePriceLineWidth: 1,
                    prevClosePriceLineColor: '#555555',
                    minTick: 'default',
                    dividendsAdjustment: null,
                    backAdjustment: false,
                    settlementAsClose: true,
                    sessionId: 'regular',
                    sessVis: false,
                    statusViewStyle: {
                      fontSize: 16,
                      showExchange: true,
                      showInterval: true,
                      symbolTextSource: 'description',
                      showSymbolAsDescription: false,
                    },
                    candleStyle: {
                      upColor: '#009900',
                      downColor: '#B80E0C',
                      drawWick: true,
                      drawBorder: true,
                      borderColor: '#000000',
                      borderUpColor: '#000000',
                      borderDownColor: '#000000',
                      wickColor: '#737375',
                      wickUpColor: '#000000',
                      wickDownColor: '#000000',
                      barColorsOnPrevClose: false,
                      drawBody: true,
                    },
                    hollowCandleStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      drawWick: true,
                      drawBorder: true,
                      borderColor: '#378658',
                      borderUpColor: '#089981',
                      borderDownColor: '#F23645',
                      wickColor: '#737375',
                      wickUpColor: '#089981',
                      wickDownColor: '#F23645',
                      drawBody: true,
                    },
                    haStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      drawWick: true,
                      drawBorder: true,
                      borderColor: '#378658',
                      borderUpColor: '#089981',
                      borderDownColor: '#F23645',
                      wickColor: '#737375',
                      wickUpColor: '#089981',
                      wickDownColor: '#F23645',
                      showRealLastPrice: false,
                      barColorsOnPrevClose: false,
                      inputs: {},
                      inputInfo: {},
                      drawBody: true,
                    },
                    barStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      barColorsOnPrevClose: false,
                      dontDrawOpen: false,
                      thinBars: true,
                    },
                    hiloStyle: {
                      color: '#2962FF',
                      showBorders: true,
                      borderColor: '#2962FF',
                      showLabels: true,
                      labelColor: '#2962FF',
                      drawBody: true,
                      fontSize: 7,
                    },
                    columnStyle: {
                      upColor: 'rgba(8, 153, 129, 0.5)',
                      downColor: 'rgba(242, 54, 69, 0.5)',
                      barColorsOnPrevClose: true,
                      priceSource: 'close',
                    },
                    lineStyle: {
                      color: '#2962FF',
                      linestyle: 0,
                      linewidth: 2,
                      priceSource: 'close',
                    },
                    lineWithMarkersStyle: {
                      color: '#2962FF',
                      linestyle: 0,
                      linewidth: 2,
                      priceSource: 'close',
                    },
                    steplineStyle: {
                      color: '#2962FF',
                      linestyle: 0,
                      linewidth: 2,
                      priceSource: 'close',
                    },
                    areaStyle: {
                      color1: 'rgba(41, 98, 255, 0.28)',
                      color2: '#2962FF',
                      linecolor: '#2962FF',
                      linestyle: 0,
                      linewidth: 1,
                      priceSource: 'close',
                      transparency: 100,
                    },
                    hlcAreaStyle: {
                      highLineColor: '#089981',
                      highLineStyle: 0,
                      highLineWidth: 2,
                      lowLineColor: '#F23645',
                      lowLineStyle: 0,
                      lowLineWidth: 2,
                      closeLineColor: '#868993',
                      closeLineStyle: 0,
                      closeLineWidth: 2,
                      highCloseFillColor: 'rgba(8, 153, 129, 0.2)',
                      closeLowFillColor: 'rgba(242, 54, 69, 0.2)',
                    },
                    renkoStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      borderUpColor: '#089981',
                      borderDownColor: '#F23645',
                      upColorProjection: '#a9dcc3',
                      downColorProjection: '#f5a6ae',
                      borderUpColorProjection: '#a9dcc3',
                      borderDownColorProjection: '#f5a6ae',
                      wickUpColor: '#089981',
                      wickDownColor: '#F23645',
                      inputs: {
                        source: 'close',
                        sources: 'Close',
                        boxSize: 3,
                        style: 'ATR',
                        atrLength: 14,
                        wicks: true,
                      },
                      inputInfo: {
                        source: { name: 'Source' },
                        sources: { name: 'Source' },
                        boxSize: { name: 'Box size' },
                        style: { name: 'Style' },
                        atrLength: { name: 'ATR length' },
                        wicks: { name: 'Wicks' },
                      },
                    },
                    pbStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      borderUpColor: '#089981',
                      borderDownColor: '#F23645',
                      upColorProjection: '#a9dcc3',
                      downColorProjection: '#f5a6ae',
                      borderUpColorProjection: '#a9dcc3',
                      borderDownColorProjection: '#f5a6ae',
                      inputs: { source: 'close', lb: 3 },
                      inputInfo: {
                        source: { name: 'Source' },
                        lb: { name: 'Number of line' },
                      },
                    },
                    kagiStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      upColorProjection: '#a9dcc3',
                      downColorProjection: '#f5a6ae',
                      inputs: {
                        source: 'close',
                        style: 'ATR',
                        atrLength: 14,
                        reversalAmount: 1,
                      },
                      inputInfo: {
                        source: { name: 'Source' },
                        style: { name: 'Style' },
                        atrLength: { name: 'ATR length' },
                        reversalAmount: { name: 'Reversal amount' },
                      },
                    },
                    pnfStyle: {
                      upColor: '#089981',
                      downColor: '#F23645',
                      upColorProjection: '#a9dcc3',
                      downColorProjection: '#f5a6ae',
                      inputs: {
                        sources: 'Close',
                        reversalAmount: 3,
                        boxSize: 1,
                        style: 'ATR',
                        atrLength: 14,
                        oneStepBackBuilding: false,
                      },
                      inputInfo: {
                        sources: { name: 'Source' },
                        boxSize: { name: 'Box size' },
                        reversalAmount: { name: 'Reversal amount' },
                        style: { name: 'Style' },
                        atrLength: { name: 'ATR length' },
                        oneStepBackBuilding: { name: 'One step back building' },
                      },
                    },
                    baselineStyle: {
                      baselineColor: '#758696',
                      topFillColor1: 'rgba(8, 153, 129, 0.28)',
                      topFillColor2: 'rgba(8, 153, 129, 0.05)',
                      bottomFillColor1: 'rgba(242, 54, 69, 0.05)',
                      bottomFillColor2: 'rgba(242, 54, 69, 0.28)',
                      topLineColor: '#089981',
                      bottomLineColor: '#F23645',
                      topLineWidth: 1,
                      bottomLineWidth: 1,
                      priceSource: 'close',
                      transparency: 50,
                      baseLevelPercentage: 50,
                    },
                    rangeStyle: {
                      barStyle: 0,
                      upColor: '#089981',
                      downColor: '#F23645',
                      upColorProjection: '#a9dcc3',
                      downColorProjection: '#f5a6ae',
                      thinBars: true,
                      candlesUpColor: '#089981',
                      candlesDownColor: '#F23645',
                      candlesBorderUpColor: '#089981',
                      candlesBorderDownColor: '#F23645',
                      candlesWickUpColor: '#089981',
                      candlesWickDownColor: '#F23645',
                      inputs: { range: 10, phantomBars: false },
                      inputInfo: {
                        range: { name: 'Range' },
                        phantomBars: { name: 'Phantom bars' },
                      },
                    },
                    symbol: 'BBCA',
                    shortName: 'BBCA',
                    timeframe: '',
                    onWidget: false,
                    interval: 'D',
                    unitId: null,
                    showInDataWindow: true,
                    showSessions: false,
                    shortname: '',
                    currencyId: null,
                    priceAxisProperties: {
                      autoScale: true,
                      autoScaleDisabled: false,
                      lockScale: false,
                      percentage: false,
                      percentageDisabled: false,
                      log: false,
                      logDisabled: false,
                      alignLabels: true,
                      isInverted: false,
                      indexedTo100: false,
                    },
                  },
                },
              ],
              mainSourceId: '_seriesId',
              stretchFactor: 4910.315574383505,
              leftAxisesState: [],
              rightAxisesState: [
                {
                  state: {
                    id: 'fuH0asbqXhZu',
                    m_priceRange: null,
                    m_isAutoScale: true,
                    m_isPercentage: false,
                    m_isIndexedTo100: false,
                    m_isLog: false,
                    m_isLockScale: false,
                    m_isInverted: false,
                    m_topMargin: 0.17,
                    m_bottomMargin: 0.15,
                    alignLabels: true,
                    logFormula: { logicalOffset: 4, coordOffset: 0.0001 },
                  },
                  sources: ['_seriesId'],
                },
              ],
              overlayPriceScales: {},
              priceScaleRatio: null,
            },
            {
              sources: [
                {
                  type: 'Study',
                  id: '1di27H',
                  state: {
                    styles: {
                      vol: {
                        display: 15,
                        linestyle: 0,
                        linewidth: 1,
                        plottype: 5,
                        trackPrice: false,
                        transparency: 0,
                        color: '#000080',
                        title: 'vol',
                      },
                      vol_ma: {
                        display: 15,
                        linestyle: 0,
                        linewidth: 2,
                        plottype: 0,
                        trackPrice: false,
                        transparency: 30,
                        color: '#0496ff',
                        title: 'vol_ma',
                      },
                    },
                    precision: 'default',
                    palettes: {
                      volumePalette: {
                        colors: {
                          0: {
                            color: 'rgba(235, 77, 92, 0.50)',
                            width: 1,
                            style: 0,
                          },
                          1: {
                            color: 'rgba(83, 185, 135, 0.50)',
                            width: 1,
                            style: 0,
                          },
                        },
                      },
                    },
                    inputs: { showMA: true, maLength: 20 },
                    bands: {},
                    graphics: {},
                    ohlcPlots: {},
                    filledAreasStyle: {},
                    filledAreas: {},
                    visible: true,
                    showLegendValues: true,
                    showLabelsOnPriceScale: true,
                    parentSources: {},
                    intervalsVisibilities: {
                      ticks: true,
                      seconds: true,
                      secondsFrom: 1,
                      secondsTo: 59,
                      minutes: true,
                      minutesFrom: 1,
                      minutesTo: 59,
                      hours: true,
                      hoursFrom: 1,
                      hoursTo: 24,
                      days: true,
                      daysFrom: 1,
                      daysTo: 366,
                      weeks: true,
                      weeksFrom: 1,
                      weeksTo: 52,
                      months: true,
                      monthsFrom: 1,
                      monthsTo: 12,
                      ranges: true,
                    },
                  },
                  zorder: -10000,
                  ownFirstValue: null,
                  metaInfo: {
                    palettes: {
                      volumePalette: {
                        colors: [{ name: 'Falling' }, { name: 'Growing' }],
                      },
                    },
                    inputs: [
                      {
                        id: 'showMA',
                        name: 'show MA',
                        defval: true,
                        type: 'bool',
                        display: 0,
                      },
                      {
                        id: 'maLength',
                        name: 'MA Length',
                        defval: 20,
                        type: 'integer',
                        min: 1,
                        max: 2000,
                        display: 15,
                      },
                    ],
                    plots: [
                      { id: 'vol', type: 'line' },
                      {
                        id: 'volumePalette',
                        palette: 'volumePalette',
                        target: 'vol',
                        type: 'colorer',
                      },
                      { id: 'vol_ma', type: 'line' },
                    ],
                    graphics: {},
                    defaults: {
                      styles: {
                        vol: {
                          display: 15,
                          linestyle: 0,
                          linewidth: 1,
                          plottype: 5,
                          trackPrice: false,
                          transparency: 0,
                          color: '#000080',
                        },
                        vol_ma: {
                          display: 15,
                          linestyle: 0,
                          linewidth: 2,
                          plottype: 0,
                          trackPrice: false,
                          transparency: 30,
                          color: '#0496FF',
                        },
                      },
                      precision: 0,
                      palettes: {
                        volumePalette: {
                          colors: {
                            0: {
                              color: 'rgba(235, 77, 92, 0.50)',
                              width: 1,
                              style: 0,
                            },
                            1: {
                              color: 'rgba(83, 185, 135, 0.50)',
                              width: 1,
                              style: 0,
                            },
                          },
                        },
                      },
                      inputs: { showMA: true, maLength: 20 },
                    },
                    _metainfoVersion: 53,
                    id: 'Volume_@tv-basicstudies-1',
                    is_hidden_study: false,
                    description: 'Volume_',
                    shortDescription: 'Volume',
                    is_price_study: false,
                    format: { type: 'volume', precision: 3 },
                    styles: {
                      vol: { title: 'Volume_', histogramBase: 0 },
                      vol_ma: { title: 'Volume MA', histogramBase: 0 },
                    },
                    description_localized: 'Volume_',
                    shortId: 'Volume_',
                    packageId: 'tv-basicstudies',
                    version: '1',
                    fullId: 'Volume_@tv-basicstudies-1',
                    productId: 'tv-basicstudies',
                    _serverMetaInfoVersion: 51,
                  },
                },
              ],
              mainSourceId: '1di27H',
              stretchFactor: 761.6340921567289,
              leftAxisesState: [],
              rightAxisesState: [
                {
                  state: {
                    id: 'OT5Qz68Vr8kM',
                    m_priceRange: null,
                    m_isAutoScale: true,
                    m_isPercentage: false,
                    m_isIndexedTo100: false,
                    m_isLog: false,
                    m_isLockScale: false,
                    m_isInverted: false,
                    m_topMargin: 0.17,
                    m_bottomMargin: 0.15,
                    alignLabels: true,
                    logFormula: { logicalOffset: 4, coordOffset: 0.0001 },
                  },
                  sources: ['1di27H'],
                },
              ],
              overlayPriceScales: {},
              priceScaleRatio: null,
            },
            {
              sources: [
                {
                  type: 'Study',
                  id: 'FRzT2x',
                  state: {
                    styles: {
                      plot_0: {
                        display: 15,
                        linestyle: 0,
                        linewidth: 2,
                        plottype: 0,
                        transparency: 40,
                        color: '#1CBECB',
                        trackPrice: false,
                        title: 'plot_0',
                      },
                    },
                    precision: 'default',
                    inputs: {},
                    bands: {},
                    graphics: {},
                    ohlcPlots: {},
                    palettes: {},
                    filledAreasStyle: {},
                    filledAreas: {},
                    visible: true,
                    showLegendValues: true,
                    showLabelsOnPriceScale: true,
                    parentSources: {},
                    intervalsVisibilities: {
                      ticks: true,
                      seconds: true,
                      secondsFrom: 1,
                      secondsTo: 59,
                      minutes: true,
                      minutesFrom: 1,
                      minutesTo: 59,
                      hours: true,
                      hoursFrom: 1,
                      hoursTo: 24,
                      days: true,
                      daysFrom: 1,
                      daysTo: 366,
                      weeks: true,
                      weeksFrom: 1,
                      weeksTo: 52,
                      months: true,
                      monthsFrom: 1,
                      monthsTo: 12,
                      ranges: true,
                    },
                  },
                  zorder: -10000,
                  ownFirstValue: null,
                  metaInfo: {
                    palettes: {},
                    inputs: [],
                    plots: [
                      {
                        id: 'plot_0',
                        type: 'line',
                        palette: 'palette_0',
                        target: 'Bandar Value (Rp)',
                      },
                    ],
                    graphics: {},
                    defaults: {
                      styles: {
                        plot_0: {
                          display: 15,
                          linestyle: 0,
                          linewidth: 2,
                          plottype: 0,
                          transparency: 11,
                          color: '#465ADB',
                        },
                      },
                      precision: 0,
                      inputs: {},
                    },
                    _metainfoVersion: 53,
                    id: 'Bandar Value (Rp)@tv-basicstudies-1',
                    name: 'Bandar Value (Rp)',
                    description: 'Bandar Value (Rp)',
                    shortDescription: 'Bandar Value (Rp)',
                    is_hidden_study: false,
                    is_price_study: false,
                    isCustomIndicator: true,
                    format: { type: 'volume', precision: 3 },
                    styles: {
                      plot_0: {
                        title: 'Bandar Value (Rp) value',
                        histogramBase: 0,
                      },
                    },
                    description_localized: 'Bandar Value (Rp)',
                    shortId: 'Bandar Value (Rp)',
                    packageId: 'tv-basicstudies',
                    version: '1',
                    fullId: 'Bandar Value (Rp)@tv-basicstudies-1',
                    productId: 'tv-basicstudies',
                    _serverMetaInfoVersion: 51,
                  },
                },
              ],
              mainSourceId: 'FRzT2x',
              stretchFactor: 639.1681834955561,
              leftAxisesState: [],
              rightAxisesState: [
                {
                  state: {
                    id: 'GN2Rji3Lwd0M',
                    m_priceRange: null,
                    m_isAutoScale: true,
                    m_isPercentage: false,
                    m_isIndexedTo100: false,
                    m_isLog: false,
                    m_isLockScale: false,
                    m_isInverted: false,
                    m_topMargin: 0.17,
                    m_bottomMargin: 0.15,
                    alignLabels: true,
                    logFormula: { logicalOffset: 4, coordOffset: 0.0001 },
                  },
                  sources: ['FRzT2x'],
                },
              ],
              overlayPriceScales: {},
              priceScaleRatio: null,
            },
            {
              sources: [
                {
                  type: 'Study',
                  id: 'Tpv9Jx',
                  state: {
                    palettes: {
                      palette_0: {
                        colors: {
                          0: { color: '#B80E0C', width: 5, style: 0 },
                          1: { color: '#009900', width: 5, style: 0 },
                        },
                      },
                    },
                    styles: {
                      plot_0: {
                        display: 15,
                        linestyle: 0,
                        linewidth: 5,
                        plottype: 5,
                        trackPrice: false,
                        transparency: 11,
                        color: '#009900',
                        title: 'plot_0',
                      },
                    },
                    precision: 'default',
                    inputs: {},
                    bands: {},
                    graphics: {},
                    ohlcPlots: {},
                    filledAreasStyle: {},
                    filledAreas: {},
                    visible: true,
                    showLegendValues: true,
                    showLabelsOnPriceScale: true,
                    parentSources: {},
                    intervalsVisibilities: {
                      ticks: true,
                      seconds: true,
                      secondsFrom: 1,
                      secondsTo: 59,
                      minutes: true,
                      minutesFrom: 1,
                      minutesTo: 59,
                      hours: true,
                      hoursFrom: 1,
                      hoursTo: 24,
                      days: true,
                      daysFrom: 1,
                      daysTo: 366,
                      weeks: true,
                      weeksFrom: 1,
                      weeksTo: 52,
                      months: true,
                      monthsFrom: 1,
                      monthsTo: 12,
                      ranges: true,
                    },
                  },
                  zorder: -10000,
                  ownFirstValue: null,
                  metaInfo: {
                    palettes: {
                      palette_0: {
                        colors: [{ name: 'Color 0' }, { name: 'Color 1' }],
                      },
                    },
                    inputs: [],
                    plots: [
                      { id: 'plot_0', type: 'line' },
                      {
                        id: 'plot_1',
                        palette: 'palette_0',
                        target: 'plot_0',
                        type: 'colorer',
                      },
                    ],
                    graphics: {},
                    defaults: {
                      palettes: {
                        palette_0: {
                          colors: [
                            { color: '#B80E0C', width: 5, style: 0 },
                            { color: '#009900', width: 5, style: 0 },
                          ],
                        },
                      },
                      styles: {
                        plot_0: {
                          display: 15,
                          linestyle: 0,
                          linewidth: 5,
                          plottype: 5,
                          trackPrice: false,
                          transparency: 11,
                          color: '#009900',
                        },
                      },
                      precision: 0,
                      inputs: {},
                    },
                    _metainfoVersion: 53,
                    id: 'Bandar Volume@tv-basicstudies-1',
                    name: 'Bandar Volume',
                    description: 'Bandar Volume',
                    shortDescription: 'Bandar Volume',
                    is_hidden_study: false,
                    is_price_study: false,
                    isCustomIndicator: true,
                    format: { type: 'volume', precision: 2 },
                    styles: {
                      plot_0: {
                        title: 'Bandar Volume value',
                        histogramBase: 0,
                        joinPoints: false,
                      },
                    },
                    description_localized: 'Bandar Volume',
                    shortId: 'Bandar Volume',
                    packageId: 'tv-basicstudies',
                    version: '1',
                    fullId: 'Bandar Volume@tv-basicstudies-1',
                    productId: 'tv-basicstudies',
                    _serverMetaInfoVersion: 51,
                  },
                },
              ],
              mainSourceId: 'Tpv9Jx',
              stretchFactor: 781.9548872180451,
              leftAxisesState: [],
              rightAxisesState: [
                {
                  state: {
                    id: 'E3WO8eglD0cw',
                    m_priceRange: null,
                    m_isAutoScale: true,
                    m_isPercentage: false,
                    m_isIndexedTo100: false,
                    m_isLog: false,
                    m_isLockScale: false,
                    m_isInverted: false,
                    m_topMargin: 0.17,
                    m_bottomMargin: 0.15,
                    alignLabels: true,
                    logFormula: { logicalOffset: 4, coordOffset: 0.0001 },
                  },
                  sources: ['Tpv9Jx'],
                },
              ],
              overlayPriceScales: {},
              priceScaleRatio: null,
            },
          ],
          timeScale: {
            m_barSpacing: 6,
            m_rightOffset: 10,
            rightOffsetPercentage: 5,
            usePercentageRightOffset: false,
          },
          chartProperties: {
            paneProperties: {
              backgroundType: 'solid',
              background: '#ffffff',
              backgroundGradientStartColor: '#ffffff',
              backgroundGradientEndColor: '#ffffff',
              gridLinesMode: 'both',
              vertGridProperties: { color: 'rgba(42, 46, 57, 0.06)' },
              horzGridProperties: { color: 'rgba(42, 46, 57, 0.06)' },
              crossHairProperties: {
                color: '#9598A1',
                style: 2,
                transparency: 0,
                width: 1,
                'color:': '#9598A1',
              },
              topMargin: 17,
              bottomMargin: 15,
              axisProperties: {
                autoScale: true,
                autoScaleDisabled: false,
                lockScale: false,
                percentage: false,
                percentageDisabled: false,
                indexedTo100: false,
                log: false,
                logDisabled: false,
                alignLabels: true,
                isInverted: false,
              },
              legendProperties: {
                showStudyArguments: true,
                showStudyTitles: true,
                showStudyValues: false,
                showSeriesTitle: true,
                showSeriesOHLC: true,
                showLegend: true,
                showBarChange: true,
                showVolume: false,
                showBackground: true,
                showPriceSource: true,
                backgroundTransparency: 50,
                showLogo: true,
                wrapText: false,
                showOnlyPriceSource: true,
              },
              separatorColor: '#E0E3EB',
              gridProperties: { color: '#e1ecf2', style: 0 },
              leftAxisProperties: {
                autoScale: true,
                autoScaleDisabled: false,
                lockScale: false,
                percentage: false,
                percentageDisabled: false,
                indexedTo100: false,
                log: false,
                logDisabled: false,
                alignLabels: true,
              },
              rightAxisProperties: {
                autoScale: true,
                autoScaleDisabled: false,
                lockScale: false,
                percentage: false,
                percentageDisabled: false,
                log: false,
                logDisabled: false,
                alignLabels: true,
                indexedTo100: false,
              },
              rightOffset: null,
            },
            scalesProperties: {
              backgroundColor: '#ffffff',
              lineColor: 'rgba(42, 46, 57, 0)',
              textColor: '#131722',
              fontSize: 12,
              scaleSeriesOnly: false,
              showSeriesLastValue: true,
              seriesLastValueMode: 1,
              showSeriesPrevCloseValue: false,
              showStudyLastValue: true,
              showSymbolLabels: false,
              showStudyPlotLabels: false,
              showBidAskLabels: false,
              showPrePostMarketPriceLabel: true,
              showFundamentalNameLabel: false,
              showFundamentalLastValue: true,
              barSpacing: 6,
              axisHighlightColor: 'rgba(41, 98, 255, 0.25)',
              axisLineToolLabelBackgroundColorCommon: '#2962FF',
              axisLineToolLabelBackgroundColorActive: '#143EB3',
              showPriceScaleCrosshairLabel: true,
              showTimeScaleCrosshairLabel: true,
              crosshairLabelBgColorLight: '#131722',
              crosshairLabelBgColorDark: '#363A45',
              showCurrency: true,
              showUnit: true,
              showLeftScale: false,
              showRightScale: true,
            },
            chartEventsSourceProperties: {
              visible: true,
              futureOnly: true,
              breaks: {
                color: 'rgba(85, 85, 85, 1)',
                visible: false,
                style: 2,
                width: 1,
              },
            },
            tradingProperties: {
              showPositions: true,
              positionPL: { visibility: true, display: 0 },
              bracketsPL: { visibility: true, display: 0 },
              showOrders: true,
              showExecutions: true,
              showExecutionsLabels: false,
              showReverse: true,
              horizontalAlignment: 2,
              extendLeft: true,
              lineLength: 5,
              lineWidth: 1,
              lineStyle: 0,
            },
            priceScaleSelectionStrategyName: 'auto',
          },
          sessions: {
            properties: {
              graphics: {
                backgrounds: {
                  outOfSession: {
                    color: '#2962FF',
                    transparency: 92,
                    visible: false,
                  },
                  preMarket: {
                    color: '#FF9800',
                    transparency: 92,
                    visible: false,
                  },
                  postMarket: {
                    color: '#2962FF',
                    transparency: 92,
                    visible: false,
                  },
                },
                vertlines: {
                  sessBreaks: {
                    color: '#4985e7',
                    style: 2,
                    visible: false,
                    width: 1,
                  },
                },
              },
            },
          },
          version: 3,
          timezone: 'Asia/Jakarta',
          shouldBeSavedEvenIfHidden: true,
          linkingGroup: null,
          lineToolsGroups: { groups: [] },
          chartId: '1',
          theme: 'light',
        },
      ],
      symbolLock: 0,
      intervalLock: 0,
      trackTimeLock: 0,
      dateRangeLock: 0,
      crosshairLock: 1,
      layoutsSizes: { s: [{ percent: 1 }] },
    },
  },
];

export default templates;
