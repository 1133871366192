import { isClient } from 'constants/app';
import isAfter from 'date-fns/isAfter';

import {
  ACCESS_TOKEN_KEY,
  ACCESS_TOKEN_KEY_EXP,
  REFRESH_TOKEN_KEY,
  REFRESH_TOKEN_KEY_EXP,
  ACCESS_USER_KEY,
} from 'constants/localstorage';
import { localStorageOrderbookHandler } from 'features/orderbook/utils';
import { decodeBase64ToJS, encodeBase64FromJS } from './general';

function getToken() {
  if (!isClient()) {
    return {
      accessToken: {
        token: null,
        expiry: null,
        isValid: null,
      },
      refresh: {
        token: null,
        expiry: null,
        isValid: null,
      },
    };
  }

  const ls = localStorage;

  // set cookies
  // format date from backend is still in seconds, while
  // JS' date is in miliseconds format, thus multiply the date by 1000
  const accessToken = ls[ACCESS_TOKEN_KEY]
    ? decodeBase64ToJS(ls[ACCESS_TOKEN_KEY])
    : null;
  const accessTokenExp = ls[ACCESS_TOKEN_KEY_EXP]
    ? parseInt(decodeBase64ToJS(ls[ACCESS_TOKEN_KEY_EXP]), 10)
    : null;
  const refreshToken = ls[REFRESH_TOKEN_KEY]
    ? decodeBase64ToJS(ls[REFRESH_TOKEN_KEY])
    : null;
  const refreshTokenExp = ls[REFRESH_TOKEN_KEY_EXP]
    ? parseInt(decodeBase64ToJS(ls[REFRESH_TOKEN_KEY_EXP]), 10)
    : null;

  // check expiry
  const currentTime = Date.now();
  const isAccessTokenValid = isAfter(accessTokenExp, currentTime);
  const isRefreshTokenValid = isAfter(refreshTokenExp, currentTime);

  return {
    accessToken: {
      token: accessToken,
      expiry: accessTokenExp,
      isValid: isAccessTokenValid,
    },
    refresh: {
      token: refreshToken,
      expiry: refreshTokenExp,
      isValid: isRefreshTokenValid,
    },
  };
}

function saveToken({
  accessToken,
  accessTokenExp,
  refreshToken,
  refreshTokenExp,
  accessUser,
}) {
  if (!isClient()) {
    return;
  }
  if (accessUser) {
    localStorage[ACCESS_USER_KEY] = encodeBase64FromJS(accessUser);
  }
  localStorage[ACCESS_TOKEN_KEY] = encodeBase64FromJS(accessToken);
  localStorage[ACCESS_TOKEN_KEY_EXP] = encodeBase64FromJS(
    accessTokenExp.toString(),
  );
  localStorage[REFRESH_TOKEN_KEY] = encodeBase64FromJS(refreshToken);
  localStorage[REFRESH_TOKEN_KEY_EXP] = encodeBase64FromJS(
    refreshTokenExp.toString(),
  );
}

function updateAccessUser(accessUser) {
  if (isClient()) {
    localStorage[ACCESS_USER_KEY] = encodeBase64FromJS(accessUser);
  }
}

function destroyToken() {
  if (isClient()) {
    localStorage.removeItem(ACCESS_USER_KEY);
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(ACCESS_TOKEN_KEY_EXP);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY_EXP);
    localStorage.removeItem('stbx-active-indexwatchlist');
    localStorage.removeItem('stbx-active-watchlist');
    localStorage.removeItem('stbx-active-widget');
    localStorage.removeItem('stbx-active-stream-header');
    localStorage.removeItem('stbx-active-stream');

    localStorage.removeItem('trade-orderbook-show-confirmation');
    localStorage.removeItem('tradeIsLogin');
    localStorage.removeItem('tradingVersion');
    localStorage.removeItem('tradingAccountType');
    localStorage.removeItem('taps');
    localStorage.removeItem('tradingBroker');
    localStorage.removeItem('lastTradingAccountType');
    localStorage.removeItem('register_trading_useractive_sinarmas');

    localStorage.removeItem('register_trading_sinarmas');
    localStorage.removeItem('register_trading_useractive');
    localStorage.removeItem('tradingBrokerList');

    localStorage.removeItem('financials-panel-amount');
    localStorage.removeItem('financials-panel-currency');
    localStorage.removeItem('financials-panel-datatype');
    localStorage.removeItem('financials-panel-lang');
    localStorage.removeItem('financials-panel-ordertype');
    localStorage.removeItem('financials-panel-reportype');
    localStorage.removeItem('financials-panel-rounding-count');
    localStorage.removeItem('financials-panel-rounding-symbol');
    localStorage.removeItem('financials-panel-statetype');
    localStorage.removeItem('crisp');

    localStorage.removeItem('formula');

    localStorage.removeItem('stbx-active-trending-date');
    localStorage.removeItem('ws');

    localStorageOrderbookHandler.remove();
    localStorage.removeItem('orderbooklistID');
    localStorage.removeItem('orderbooklistName');
  }
}

/**
 * getUserAccess
 * to get user data from auth login
 * @param {*} ctx
 */
function getUserAccess() {
  return isClient() && localStorage && localStorage[ACCESS_USER_KEY]
    ? decodeBase64ToJS(localStorage[ACCESS_USER_KEY])
    : {};
}

const setLocalCrispSettings = (crisp) => {
  localStorage.location = window.location.origin;
  localStorage.crisp = encodeBase64FromJS(crisp);
};

const setLocalIntercomSettings = (intercom) => {
  localStorage.location = window.location.origin;
  localStorage.intercom = encodeBase64FromJS(intercom);
};

const isLoggedIn = () => isClient() && Boolean(localStorage?.at);

export default {
  isLoggedIn,
  getToken,
  saveToken,
  destroyToken,
  getUserAccess,
  updateAccessUser,
  setLocalCrispSettings,
  setLocalIntercomSettings,
};
