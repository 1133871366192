import create from 'zustand/vanilla';

interface MainLayoutSlice {
  errorCode: string | number | null;
  errorMessage: string | null;
  errorType: string | null;
  setErrorCode: (
    code: string | number,
    message: string | null,
    type: string | null,
  ) => void;
  resetErrorCode: () => void;
}

const initialState = {
  errorCode: null,
  errorMessage: '',
  errorType: '',
};

const mainLayoutStore = create<MainLayoutSlice>((set) => ({
  ...initialState,
  setErrorCode: (code, message = '', type = '') => {
    set({ errorCode: code, errorMessage: message, errorType: type });
  },
  resetErrorCode: () => {
    set(initialState);
  },
}));

export default mainLayoutStore;
